<template>
  <div>
    <v-dialog v-model="show" max-width="500px">
      <v-card>
        <v-card-title>{{
          $t("product.regularizeOvertime.title")
        }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="text-subtitle-1 mb-4">
                  {{ $t("product.regularizeOvertime.message") }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="booking-details">
                  <div class="text-subtitle-2 mb-2">
                    {{ $t("product.regularizeOvertime.initialBooking") }}:
                  </div>
                  <div class="ml-3 mb-4">
                    <div>
                      {{ $t("product.regularizeOvertime.duration") }}:
                      <span class="font-weight-bold">{{
                        formattedInitialDuration
                      }}</span>
                    </div>
                    <div>
                      {{ $t("product.regularizeOvertime.amount") }}:
                      <span class="font-weight-bold"
                        >{{ formattedInitialAmount }} €</span
                      >
                    </div>
                  </div>

                  <div class="text-subtitle-2 mb-2">
                    {{ $t("product.regularizeOvertime.overtime") }}:
                  </div>
                  <div class="ml-3 mb-4">
                    <div>
                      {{ $t("product.regularizeOvertime.duration") }}:
                      <span class="font-weight-bold error--text">{{
                        formattedOvertimeDuration
                      }}</span>
                    </div>
                    <div>
                      {{ $t("product.regularizeOvertime.amount") }}:
                      <span class="font-weight-bold error--text"
                        >{{ formattedOvertimeAmount }} €</span
                      >
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-alert type="warning" class="mb-0" prominent dense>
                  {{
                    $t("product.regularizeOvertime.safetyWarning", {
                      interval: booking.product.type.safetyInterval,
                    })
                  }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" :loading="loading" @click="confirmRegularize">
            {{
              $t("product.regularizeOvertime.confirm", {
                amount: formattedOvertimeAmount,
              })
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="6000">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "RegularizeOvertimeDialog",
  props: {
    value: Boolean,
    booking: Object,
  },
  data() {
    return {
      loading: false,
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    formattedInitialDuration() {
      if (!this.booking) return "";
      const startDate = new Date(this.booking.startDate);
      const endDate = new Date(this.booking.endDate);
      const diffMs = endDate - startDate;
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}m`;
    },
    formattedInitialAmount() {
      if (!this.booking) return "0.00";
      return (this.booking.price / 100).toFixed(2);
    },
    formattedOvertimeDuration() {
      if (!this.booking) return "";
      const endDate = new Date(this.booking.endDate);
      const now = new Date();
      const diffMs = now - endDate;
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}m`;
    },
    formattedOvertimeAmount() {
      if (!this.booking) return "0.00";

      try {
        const endDate = new Date(this.booking.endDate);
        const now = new Date();
        const exceedingMinutes = Math.floor((now - endDate) / (1000 * 60));
        const exceededHours = Math.ceil(exceedingMinutes / 60.0);

        const overchargeRules = this.booking.product.type.overcharge
          ? JSON.parse(this.booking.product.type.overcharge)
          : null;

        if (!overchargeRules) return "0.00";

        const { pricePerHour, exceedingPrice } = overchargeRules;

        if (
          pricePerHour.length === 0 ||
          exceededHours > pricePerHour[pricePerHour.length - 1][0]
        ) {
          return exceedingPrice;
        }

        let overchargeRate = 0;

        for (let i = exceededHours; i > 0; i--) {
          const applicableRule = pricePerHour.find((rule) => i <= rule[0]);

          overchargeRate += applicableRule[1];
        }

        // Round to 2 decimal places
        return (Math.round(overchargeRate * 100) / 100).toFixed(2);
      } catch (error) {
        console.error("Error calculating overtime amount:", error);
        return "0.00";
      }
    },
  },
  methods: {
    async confirmRegularize() {
      this.loading = true;

      try {
        const origin = location.origin;
        const successUrl = `${origin}/booking?result=success&type=regularize&orderId=${this.booking.orderId}&bookingId=${this.booking.id}`;
        const cancelUrl = `${origin}/booking?result=failure&type=regularize&orderId=${this.booking.orderId}&bookingId=${this.booking.id}`;

        const response = await this.$api.user.booking.regularizeOvertime(
          this.booking.id,
          {
            successUrl,
            cancelUrl,
          }
        );

        if (typeof localStorage !== "undefined") {
          const bookingCodes =
            JSON.parse(localStorage.getItem("bookingCodes")) || {};
          bookingCodes[this.booking.id] = this.booking.code;
          localStorage.setItem("bookingCodes", JSON.stringify(bookingCodes));
        }

        const { paymentUrl, status } = response;

        if (!paymentUrl) {
          if (status === "OK") {
            this.$router.push(
              `/booking?result=success&type=regularize&orderId=${this.booking.orderId}&bookingId=${this.booking.id}`
            );
          } else {
            this.$set(this, "snackbar", {
              show: true,
              text: this.$t("product.regularizeOvertime.error"),
              color: "error",
            });
          }
        } else {
          // Redirect to Stripe payment
          location = paymentUrl;
        }
      } catch (error) {
        console.error(error);
        this.$set(this, "snackbar", {
          show: true,
          text: this.$t("product.regularizeOvertime.error"),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<style scoped>
.booking-details {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
}
</style>
