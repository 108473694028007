var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"filter"},[_c('v-fade-transition',[(_vm.currentStep === 'filters')?_c('div',{attrs:{"transition":"fade-transition"}},[_c('h2',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("product_searcher.lookingfor"))+" ")]),_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"outlined":"","label":_vm.$t('product_searcher.producttype'),"items":_vm.types,"item-text":"label","item-value":"id","multiple":"","value":_vm.filters.types.length
                  ? _vm.filters.types
                  : [_vm.$t('product_searcher.allproducts')]},on:{"input":function($event){_vm.filters.types =
                  $event.filter(
                    function (e) { return e !== _vm.$t('product_searcher.allproducts'); }
                  ) || []}}})],1)],1),_c('v-row',[_c('v-col',[_c('h2',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.$t("product_searcher.fordates"))+" ")]),_c('time-filter',{attrs:{"label":_vm.$t('product_searcher.bookingdates'),"mode":_vm.timeFilterMode,"selectedProductTypes":_vm.filters.types.length ? _vm.filters.types : undefined,"showOnMount":true},model:{value:(_vm.filters.dates),callback:function ($$v) {_vm.$set(_vm.filters, "dates", $$v)},expression:"filters.dates"}})],1)],1),(_vm.filtersValid)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"primary","large":""},on:{"click":function($event){return _vm.loadAreas()}}},[_vm._v(_vm._s(_vm.$t("product_searcher.search")))])],1)],1):_vm._e()],1):_vm._e()]),_c('v-slide-y-transition',[(_vm.currentStep !== 'filters')?_c('v-row',[_c('v-col',{on:{"click":function($event){_vm.currentStep = 'filters'}}},[_c('v-text-field',{attrs:{"value":_vm.formattedFilters,"disabled":""}})],1)],1):_vm._e()],1)],1),_c('v-container',[(_vm.currentStep === 'products')?_c('v-row',[_c('v-col',[_c('v-card-title',[_vm._v(_vm._s(_vm.selectedArea.label))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.selectedArea.address))])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"smoothCol",attrs:{"sm":_vm.mapCols,"cols":"12"}},[_c('v-slide-y-reverse-transition',[(_vm.noAvailableProducts)?_c('h1',{staticClass:"watermark"},[_vm._v(" "+_vm._s(_vm.$t("product_searcher.notpossible"))+" ")]):_vm._e()]),_c('div',{ref:"mapContainer",class:[_vm.noAvailableProducts ? 'disabled' : '', 'mapContainer'],style:({ width: '100%', height: _vm.mapHeight + 'px' })},[_c('area-map',{ref:"map",staticClass:"area_map",attrs:{"elevtion":"10","areas":_vm.areas,"my_turn":_vm.currentStep == 'areas'},on:{"selected":_vm.selectArea,"cancel":function($event){_vm.currentStep = 'areas';
              _vm.selectedArea = undefined;}}})],1)],1),_c('v-col',{attrs:{"sm":"","cols":"12"}},[_c(_vm.$is_mobile
              ? 'v-slide-y-reverse-transition'
              : 'v-slide-x-reverse-transition',{tag:"component"},[(_vm.currentStep === 'products' && _vm.selectedAreaProducts.length)?_c('div',[(_vm.selectedArea.productDisplay == 'types')?_c('product-withoutlist',{attrs:{"products":_vm.selectedAreaProducts,"isProductInCart":_vm.isProductInCart,"filterDates":_vm.filters.dates,"types":_vm.types},on:{"select":_vm.selectProduct}}):_c('product-list',{attrs:{"products":_vm.selectedAreaProducts,"isProductInCart":_vm.isProductInCart,"filterDates":_vm.filters.dates,"types":_vm.types},on:{"select":_vm.selectProduct}})],1):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }