<template>
  <div>
    <!-- -------------------------------------------------- PLACEHOLDER -->
    <v-container class="filter">
      <v-fade-transition>
        <div v-if="currentStep === 'filters'" transition="fade-transition">
          <h2 class="secondary--text">
            {{ $t("product_searcher.lookingfor") }}
          </h2>
          <v-row>
            <!-- -------------------------------------------------- TYPES -->
            <v-col>
              <v-combobox
                outlined
                :label="$t('product_searcher.producttype')"
                :items="types"
                item-text="label"
                item-value="id"
                multiple
                :value="
                  filters.types.length
                    ? filters.types
                    : [$t('product_searcher.allproducts')]
                "
                @input="
                  filters.types =
                    $event.filter(
                      (e) => e !== $t('product_searcher.allproducts')
                    ) || []
                "
              ></v-combobox>
            </v-col>
          </v-row>
          <!-- -------------------------------------------------- TIMES -->
          <v-row>
            <v-col>
              <h2 class="secondary--text">
                {{ $t("product_searcher.fordates") }}
              </h2>
              <time-filter
                v-model="filters.dates"
                :label="$t('product_searcher.bookingdates')"
                :mode="timeFilterMode"
                :selectedProductTypes="
                  filters.types.length ? filters.types : undefined
                "
                :showOnMount="true"
              ></time-filter>
            </v-col>
          </v-row>
          <v-row v-if="filtersValid">
            <v-col>
              <v-btn @click="loadAreas()" block color="primary" large>{{
                $t("product_searcher.search")
              }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-fade-transition>
      <v-slide-y-transition>
        <v-row v-if="currentStep !== 'filters'">
          <v-col @click="currentStep = 'filters'">
            <v-text-field :value="formattedFilters" disabled></v-text-field>
          </v-col>
        </v-row>
      </v-slide-y-transition>
    </v-container>
    <v-container>
      <!-- -------------------------------------------------- AREA  / PRODUCTS -->
      <v-row v-if="currentStep === 'products'">
        <v-col>
          <v-card-title>{{ selectedArea.label }}</v-card-title>
          <v-card-subtitle>{{ selectedArea.address }}</v-card-subtitle>
        </v-col>
      </v-row>
      <v-row>
        <v-col :sm="mapCols" cols="12" class="smoothCol">
          <!-- ---------------------------------- MAP -->
          <v-slide-y-reverse-transition>
            <h1 class="watermark" v-if="noAvailableProducts">
              {{ $t("product_searcher.notpossible") }}
            </h1>
          </v-slide-y-reverse-transition>
          <div
            :class="[noAvailableProducts ? 'disabled' : '', 'mapContainer']"
            ref="mapContainer"
            :style="{ width: '100%', height: mapHeight + 'px' }"
          >
            <area-map
              class="area_map"
              elevtion="10"
              ref="map"
              :areas="areas"
              :my_turn="currentStep == 'areas'"
              @selected="selectArea"
              @cancel="
                currentStep = 'areas';
                selectedArea = undefined;
              "
            ></area-map>
          </div>
        </v-col>
        <v-col sm="" cols="12">
          <!-- ---------------------------------- PRODUCT LIST -->
          <component
            :is="
              $is_mobile
                ? 'v-slide-y-reverse-transition'
                : 'v-slide-x-reverse-transition'
            "
          >
            <div
              v-if="currentStep === 'products' && selectedAreaProducts.length"
            >
              <product-withoutlist
                v-if="selectedArea.productDisplay == 'types'"
                :products="selectedAreaProducts"
                :isProductInCart="isProductInCart"
                :filterDates="filters.dates"
                :types="types"
                @select="selectProduct"
              ></product-withoutlist>
              <product-list
                v-else
                :products="selectedAreaProducts"
                :isProductInCart="isProductInCart"
                :filterDates="filters.dates"
                :types="types"
                @select="selectProduct"
              ></product-list>
            </div>
          </component>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AreaMap from "@/components/area-map.vue";
import ProductList from "@/components/product-list.vue";
import timeFilter from "@/components/time-filter.vue";
import {
  VSlideYReverseTransition,
  VSlideXReverseTransition,
} from "vuetify/lib/components/transitions";

import { format } from "date-fns";
import { v4 as uuid } from "uuid";

import { mapActions, mapGetters } from "vuex";
import ProductWithoutlist from "@/components/product-withoutlist.vue";
import { getReservationPrice } from "@/utils/getReservationPrice";

/*
@desc Product search Main Component (filter search, select on map, view products, add to cart). This component uses search steps to guide the user into looking for what it's looking for
*/
export default {
  components: {
    AreaMap,
    ProductList,
    ProductWithoutlist,
    timeFilter,
    VSlideYReverseTransition,
    VSlideXReverseTransition,
  },
  data: () => ({
    filters: {
      types: [],
      dates: ["", ""],
    },

    types: [],

    areas: {},

    selectedArea: undefined,

    currentStep: "filters", // "filters", "areas", "products"

    mapHeight: 0,
    mapCols: 12,
  }),

  watch: {
    areas: {
      handler() {
        if (this.availableAreas.length == 1 && this.currentStep === "areas") {
          const area = this.availableAreas[0];
          const { lat, lng } = area;

          setTimeout(() => {
            this.$refs.map.select_marker({
              area_id: area.id,
              geo: { lat, lng },
            });
          }, 100);
        }
      },
      deep: true,
    },
    currentStep() {
      setTimeout(() => this.calculateMapSize(), 500);
    },
  },

  computed: {
    selectedAreaProducts() {
      return Object.values(this.selectedArea.products);
    },
    timeFilterMode() {
      return process.env.VUE_APP_TIME_MODE ?? "hours";
    },
    noAvailableProducts() {
      return this.currentStep == "areas" && this.availableAreas.length == 0;
    },
    formattedFilters() {
      const {
        dates: [startDate, endDate],
      } = this.filters;

      const start = format(
        startDate,
        this.$t("product_searcher.datetimeformat")
      );
      const end = format(endDate, this.$t("product_searcher.datetimeformat"));
      const types = this.filters.types.map((type) => type.label).join(", ");

      return `${
        types.length ? types : this.$t("product_searcher.allproducts")
      } - ${start} -> ${end}`;
    },
    filtersValid() {
      return this.filters.dates.every((date) => !!date);
    },
    availableAreas() {
      return Object.values(this.areas).filter(
        (area) =>
          area.activated &&
          Object.values(area.products).find(
            (product) => product.available && product.activated
          )
      );
    },

    ...mapGetters(["cart_hash_map"]),
  },

  methods: {
    ...mapActions(["add_to_cart", "set_back_state", "update_cart_with"]),
    calculateMapSize() {
      const windowHeight = window.innerHeight;
      const top = this.$refs.mapContainer?.offsetTop;
      const maxHeight = windowHeight - top - 10;

      this.mapHeight = this.$is_mobile
        ? {
            filters: 0,
            areas: maxHeight,
            products: 100,
          }[this.currentStep]
        : {
            filters: 0,
            areas: maxHeight,
            products: maxHeight,
          }[this.currentStep];

      this.mapCols = !this.$is_mobile
        ? {
            filters: 0,
            areas: 12,
            products: 4,
          }[this.currentStep]
        : 12;

      setTimeout(
        () => this.$refs.map.$refs.map.mapObject.invalidateSize(),
        300
      );
    },
    selectArea(area) {
      this.selectedArea = area;
      this.goToStep("products");
    },
    goToStep(step) {
      this.set_back_state(this.currentStep);
      this.currentStep = step;
    },
    productToItem(product) {
      const productType = this.types.find((t) => t.label === product.type);

      return {
        id: uuid(),
        start_date: this.filters.dates[0].getTime() / 1000,
        end_date: this.filters.dates[1].getTime() / 1000,
        product_id: product.id,
        product,
        price: getReservationPrice(
          (this.filters.dates[1].getTime() - this.filters.dates[0].getTime()) /
            3600000,
          productType.price
        ),
      };
    },
    getItemHash(item) {
      const { product_id, start_date, end_date } = item;
      return product_id + start_date + end_date;
    },
    selectProduct(product) {
      this.update_cart_with(this.productToItem(product));
    },
    isProductInCart(product) {
      return !!this.cart_hash_map[
        this.getItemHash(this.productToItem(product))
      ];
    },
    async loadAreas() {
      const {
        types,
        dates: [startDate, endDate],
      } = this.filters;
      this.$set(this, "areas", {});

      if (!startDate || !endDate) {
        return this.goToStep("filters");
      }

      const start = parseInt(startDate.getTime() / 1000);
      const end = parseInt(endDate.getTime() / 1000);
      const typeIds = types.map((type) => type.id);
      if (!typeIds.length) {
        typeIds.push(...this.types.map((type) => type.id));
      }

      const areas = await this.$api.user.filters.areas(
        typeIds.join(","),
        start,
        end
      );
      this.$set(this, "areas", areas);
      this.goToStep("areas");
    },
    async loadProductTypes() {
      const types = await this.$api.user.filters.types();
      this.$set(this, "types", types);
    },
  },

  async mounted() {
    this.loadProductTypes();
    this.set_back_state(this.currentStep);
  },
};
</script>

<style scoped>
.filter {
  max-width: 100%;
  width: 700px !important;
  transition: all 0.5s;
}
.mapContainer {
  transition: height 0.2s;
}
.mapContainer.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.smoothCol {
  transition: all 0.2s;
}
.watermark {
  opacity: 0.6;
  font-size: 40px;
  top: 55% !important;
  width: calc(100% - 40px) !important;
  margin-left: 20px;
  margin-right: 20px;
}
.v-text-field {
  margin-bottom: -20px;
}
h2 {
  margin-bottom: 10px;
}
</style>
