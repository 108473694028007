/**
 * Calculates the time difference between two dates in minutes
 * @param {string|Date} startDate - The start date
 * @param {string|Date} endDate - The end date
 * @returns {number} The difference in minutes
 */
export function calculateTimeDifferenceInMinutes(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return Math.floor((end - start) / (1000 * 60));
}

/**
 * Formats a duration in minutes to a human readable string
 * @param {number} minutes - The duration in minutes
 * @returns {string} Formatted duration (e.g. "1j 2h 30min")
 */
export function formatDuration(minutes) {
  const days = Math.floor(minutes / (24 * 60));
  const hours = Math.floor((minutes % (24 * 60)) / 60);
  const remainingMinutes = minutes % 60;

  const parts = [];
  if (days > 0) parts.push(`${days}j`);
  if (hours > 0) parts.push(`${hours}h`);
  if (remainingMinutes > 0 || parts.length === 0) {
    parts.push(`${remainingMinutes}min`);
  }

  return parts.join(" ");
}

/**
 * Checks if a duration is within time constraints
 * @param {Object} constraints - The time constraints object
 * @param {number} constraints.minimumTime - Minimum duration in minutes
 * @param {number} constraints.maximumTime - Maximum duration in minutes
 * @param {number} duration - The actual duration in minutes
 * @returns {boolean} Whether the duration is within constraints
 */
export function isWithinTimeConstraints(constraints, duration) {
  const { minimumTime, maximumTime } = constraints;

  if (minimumTime && minimumTime > 0 && duration < minimumTime) {
    return false;
  }
  if (maximumTime && maximumTime > 0 && duration > maximumTime) {
    return false;
  }

  return true;
}
