/**
 * Calculates total weight of reserved products
 * @param {Array} reservedProducts - Array of reserved products
 * @returns {number} Total weight
 */
export function calculateTotalWeight(reservedProducts) {
  return reservedProducts.reduce(
    (total, product) => total + product.meta.weight,
    0
  );
}

/**
 * Finds the optimal weight that best matches the target weight when combined with reserved products
 * @param {Array} availableProducts - Array of available products
 * @param {Array} reservedProducts - Array of reserved products
 * @param {number} targetWeight - Target total weight to achieve
 * @returns {number} Optimal weight value
 */
function findOptimalWeight(availableProducts, reservedProducts, targetWeight) {
  const currentTotalWeight = calculateTotalWeight(reservedProducts);

  return availableProducts.reduce((bestWeight, product) => {
    const productWeight = product.meta.weight;

    // If we find an exact match, return it immediately
    if (productWeight + currentTotalWeight === targetWeight) {
      return productWeight;
    }

    // Otherwise, find the weight that gets us closest to the target
    if (
      bestWeight === null ||
      Math.abs(targetWeight - (bestWeight + currentTotalWeight)) >
        Math.abs(productWeight + currentTotalWeight - targetWeight)
    ) {
      return productWeight;
    }

    return bestWeight;
  }, null);
}

/**
 * Finds the optimal product based on weight balancing
 * @param {Array} availableProducts - Array of available products
 * @param {Array} reservedProducts - Array of reserved products
 * @param {number} targetWeight - Target total weight to achieve
 * @returns {Object|undefined} The optimal product
 */
export function findOptimalProduct(
  availableProducts,
  reservedProducts,
  targetWeight
) {
  if (!availableProducts || availableProducts.length === 0) {
    return undefined;
  }

  const optimalWeight = findOptimalWeight(
    availableProducts,
    reservedProducts,
    targetWeight
  );

  return availableProducts.find(
    (product) => product.meta.weight === optimalWeight
  );
}
