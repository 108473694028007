<template>
  <v-dialog
    v-model="isDatePickerVisible"
    max-width="700px"
    :fullscreen="$is_mobile"
    transition="dialog-bottom-transition"
    v-if="selectedDays.length > 0"
  >
    <!-- ----------------------------------------------------- TEXT FIELD -->
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        prepend-inner-icon="mdi-calendar"
        outlined
        readonly
        v-on="on"
        :value="formattedDateRange"
      ></v-text-field>
    </template>
    <!-- ----------------------------------------------------- DIALOG -->

    <v-card class="pa-5 text-center">
      <v-card-title class="justify-center mb-5">
        <from-to
          :from="datesWithHours[0]"
          :to="datesWithHours[1]"
          :vertical="$is_mobile"
          :dense="$is_mobile"
        ></from-to>
      </v-card-title>

      <!-- ----------------------------- date picker -->
      <v-date-picker
        :no-title="$is_mobile"
        class="dater"
        :value="lockStartDate ? selectedDays[1] : selectedDaysDatePicker"
        @input="onDateChange"
        :range="!lockStartDate"
        color="primary"
        :locale="$i18n.locale"
        first-day-of-week="1"
        elevation="5"
        :allowed-dates="allowedDates"
      ></v-date-picker>
      <!-- ----------------------------- timer sliders -->
      <div :class="['time_slider text-left mt-7', $is_mobile ? 'mx-5' : '']">
        <!-- ------------------------- multi date -->
        <v-row class="ma-0">
          <v-col
            class="ma-0 pa-0"
            :cols="$is_mobile ? 12 : 5"
            style="display: flex"
          >
            <v-spacer></v-spacer>
            <time-selecter
              v-model="selectedDayTimes[0]"
              :title="formattedSelectedDays[0]"
              :times="availableFirstDateTimes"
              :disabled="lockStartDate || this.mode === 'mids'"
              :key="'first-' + JSON.stringify(selectedDayTimes[0])"
            >
            </time-selecter>
            <v-spacer v-if="$is_mobile"></v-spacer>
          </v-col>
          <v-col :cols="$is_mobile ? 12 : 2" style="display: flex">
            <v-spacer></v-spacer>
            <v-icon class="mr-3 ml-3" small
              >mdi-arrow-{{ $is_mobile ? "down" : "right" }}</v-icon
            >
            <v-spacer></v-spacer>
          </v-col>
          <v-col
            class="ma-0 pa-0"
            :cols="$is_mobile ? 12 : 5"
            style="display: flex"
          >
            <v-spacer v-if="$is_mobile"></v-spacer>
            <time-selecter
              v-if="formattedSelectedDays[1]"
              v-model="selectedDayTimes[1]"
              :title="formattedSelectedDays[1]"
              :times="availableLastDateTimes"
              :disabled="this.mode === 'mids'"
              :key="'last-' + JSON.stringify(selectedDayTimes[1])"
            >
            </time-selecter>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </div>
      <v-card-actions class="mt-10">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="cancel">{{ $t("cancel") }}</v-btn>
        <v-btn color="primary" @click="validate" :disabled="!canConfirm">{{
          $t("validate")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from "date-fns";
import FromTo from "./from-to.vue";
import TimeSelecter from "./time-selecter.vue";
/*
@desc Date Time selection system featuring 4 time modes:
mids (opening/closing), jumps (special hours), hours (every hours), quarters (every 15 minutes)
*/
export default {
  props: [
    "value",
    "label",
    "mode",
    "lockStartDate",
    "enforcedMinDate",
    "selectedProductTypes",
    "area",
    "showOnMount",
  ],
  components: { FromTo, TimeSelecter },
  data: () => ({
    isDatePickerVisible: false,
    // Buffer the v-model of date picker to handle single date as range (same date)
    selectedDaysDatePicker: [],
    selectedDays: [], // [YYYY-MM-DD, YYYY-MM-DD]
    selectedDayTimes: [], // [[HH, MM], [HH, MM]]
    maximumDays: 30,
    areas: [],
    productTypes: [],
  }),
  watch: {
    selectedDays(newVal) {
      if (!this.lockStartDate) return;

      const d1 = format(this.value[0], "yyyy-MM-dd");
      if (newVal[0] !== d1) {
        const t1 = format(this.value[0], "HH:mm")
          .split(":")
          .map((e) => parseInt(e));

        this.selectedDays = [d1];
        this.selectedDayTimes = [t1, this.selectedDayTimes[1]];
      }
    },
    availableFirstDateTimes(newVal) {
      if (
        !newVal.length ||
        newVal.find(
          (t) =>
            t[0] === this.selectedDayTimes[0][0] &&
            t[1] === this.selectedDayTimes[0][1]
        )
      )
        return;

      this.$set(this, "selectedDayTimes", [
        newVal[0],
        this.selectedDayTimes[1],
      ]);
    },
    availableLastDateTimes(newVal) {
      if (
        !newVal.length ||
        newVal.find(
          (t) =>
            t[0] === this.selectedDayTimes[1][0] &&
            t[1] === this.selectedDayTimes[1][1]
        )
      )
        return;

      this.$set(this, "selectedDayTimes", [
        this.selectedDayTimes[0],
        newVal[newVal.length - 1],
      ]);
    },
  },
  computed: {
    maxReservationTimeHours() {
      const productTypes = this.selectedProductTypes || this.productTypes;

      return (
        productTypes.reduce((max, product) => {
          return Math.max(max, product.maximumTime / 60);
        }, 0) || this.maximumDays * 24
      );
    },

    openingHour() {
      if (this.area) {
        // For specific area, get hours for current/selected day
        const bookableHours = JSON.parse(this.area.bookableHours || "[]");
        const dayOfWeek = this.selectedDays[0]
          ? new Date(this.selectedDays[0]).getDay()
          : new Date().getDay();
        // Convert Sunday (0) to 7 to match the format where Monday is 1
        const adjustedDay = dayOfWeek === 0 ? 7 : dayOfWeek;
        const todayHours = bookableHours.find((h) => h.day === adjustedDay);
        return todayHours ? todayHours.open : 0;
      }

      // For all areas, find earliest opening hour for current/selected day
      const allBookableHours = this.areas.map((area) => {
        try {
          return JSON.parse(area.bookableHours || "[]");
        } catch {
          return [];
        }
      });

      if (allBookableHours.length === 0) return 0;

      const dayOfWeek = this.selectedDays[0]
        ? new Date(this.selectedDays[0]).getDay()
        : new Date().getDay();
      const adjustedDay = dayOfWeek === 0 ? 7 : dayOfWeek;

      const openingHours = allBookableHours
        .map((hours) => {
          const dayHours = hours.find((h) => h.day === adjustedDay);
          return dayHours ? dayHours.open : 24; // Use 24 as sentinel value
        })
        .filter((hour) => hour !== 24);

      return openingHours.length > 0 ? Math.min(...openingHours) : 0;
    },

    closingHour() {
      if (this.area) {
        // For specific area, get hours for current/selected day
        const bookableHours = JSON.parse(this.area.bookableHours || "[]");
        const dayOfWeek = this.selectedDays[0]
          ? new Date(this.selectedDays[0]).getDay()
          : new Date().getDay();
        const adjustedDay = dayOfWeek === 0 ? 7 : dayOfWeek;
        const todayHours = bookableHours.find((h) => h.day === adjustedDay);
        return todayHours ? todayHours.close : 23;
      }

      // For all areas, find latest closing hour for current/selected day
      const allBookableHours = this.areas.map((area) => {
        try {
          return JSON.parse(area.bookableHours || "[]");
        } catch {
          return [];
        }
      });

      if (allBookableHours.length === 0) return 23;

      const dayOfWeek = this.selectedDays[0]
        ? new Date(this.selectedDays[0]).getDay()
        : new Date().getDay();
      const adjustedDay = dayOfWeek === 0 ? 7 : dayOfWeek;

      const closingHours = allBookableHours
        .map((hours) => {
          const dayHours = hours.find((h) => h.day === adjustedDay);
          return dayHours ? dayHours.close : -1; // Use -1 as sentinel value
        })
        .filter((hour) => hour !== -1);

      return closingHours.length > 0 ? Math.max(...closingHours) : 23;
    },

    firstDateWithTime() {
      if (!this.selectedDays[0]) return;

      const firstDate = new Date(this.selectedDays[0]);
      firstDate.setHours(...this.selectedDayTimes[0]);
      return firstDate;
    },

    lastDateWithTime() {
      if (!this.selectedDays[1]) return;

      const lastDate = new Date(this.selectedDays[1]);
      lastDate.setHours(...this.selectedDayTimes[1]);
      return lastDate;
    },

    minDate() {
      if (this.enforcedMinDate) {
        return this.enforcedMinDate;
      }

      let minDate = new Date();

      // Ensure returned date respects opening hours
      const currentHour = minDate.getHours();
      if (currentHour >= this.closingHour) {
        minDate.setDate(minDate.getDate() + 1);
      }

      if (currentHour >= this.closingHour || currentHour < this.openingHour) {
        minDate.setHours(this.openingHour, 0, 0, 0);
      }

      return minDate;
    },

    maxDate() {
      if (this.lastDateWithTime && !this.firstDateWithTime) {
        return this.lastDateWithTime;
      }

      const now = new Date();
      let maxDate = now;
      maxDate.setDate(maxDate.getDate() + this.maximumDays);
      maxDate.setHours(this.closingHour, 0, 0, 0);

      if (
        this.lockStartDate ||
        (this.selectedDaysDatePicker[0] && !this.selectedDaysDatePicker[1])
      ) {
        // Further date from firstDateWithTime
        const furtherDate = new Date(this.firstDateWithTime);
        // Calculate days and remaining hours
        const daysToAdd = Math.floor(this.maxReservationTimeHours / 24);
        const hoursToAdd = this.maxReservationTimeHours % 24;

        furtherDate.setDate(furtherDate.getDate() + daysToAdd);
        furtherDate.setHours(furtherDate.getHours() + hoursToAdd);

        if (furtherDate < maxDate) maxDate = furtherDate;
      }

      // Ensure returned date respects opening hours
      const currentHour = maxDate.getHours();
      if (currentHour < this.openingHour) {
        maxDate.setDate(maxDate.getDate() - 1);
      }

      if (currentHour >= this.closingHour || currentHour < this.openingHour) {
        maxDate.setHours(this.closingHour, 0, 0, 0);
      }

      return maxDate;
    },

    allowedDates() {
      return (val) => {
        const date = new Date(val);
        // Set time to noon to avoid timezone issues when comparing dates
        date.setHours(12, 0, 0, 0);

        const min = this.enforcedMinDate
          ? new Date(this.enforcedMinDate)
          : this.selectedDaysDatePicker[1]
          ? new Date(this.minDate)
          : this.firstDateWithTime ?? new Date(this.minDate);
        const max = new Date(this.maxDate);

        min.setHours(12, 0, 0, 0);
        max.setHours(12, 0, 0, 0);

        // Check if date is within allowed range
        if (date < min || date > max) return false;

        // Get day of week (1-7, Monday-Sunday)
        const dayOfWeek = date.getDay();
        const adjustedDay = dayOfWeek === 0 ? 7 : dayOfWeek;

        if (this.area?.bookableHours) {
          // For specific area, check if day is in bookable hours
          try {
            const bookableHours = JSON.parse(this.area.bookableHours || "[]");
            return bookableHours.some((h) => h.day === adjustedDay);
          } catch {
            return true; // If parsing fails, allow all days
          }
        }

        // For all areas, check if day is bookable in at least one area
        return this.areas.some((area) => {
          if (!area.bookableHours) return true;
          try {
            const bookableHours = JSON.parse(area.bookableHours || "[]");
            return bookableHours.some((h) => h.day === adjustedDay);
          } catch {
            return true; // If parsing fails, allow all days
          }
        });
      };
    },

    availableFirstDateTimes() {
      if (this.lockStartDate) {
        return [
          format(this.value[0], "HH:mm")
            .split(":")
            .map((e) => parseInt(e)),
        ];
      }

      return this.getAvailableTimes(this.selectedDays[0]);
    },

    availableLastDateTimes() {
      return this.getAvailableTimes(this.selectedDays[1]);
    },

    datesWithHours() {
      return this.selectedDays.map((d, i) => {
        const date = new Date(d);

        const selectedDayTimes = this.selectedDayTimes[i];
        date.setHours(...selectedDayTimes);

        return date;
      });
    },
    formattedSelectedDays() {
      return this.selectedDays.map((d) =>
        format(this.$utils.check_date(d), "dd/MM/yyyy")
      );
    },
    formattedDateRange() {
      if (!this.value[0] || !this.value[1]) return "";

      if (!this.value[1]) {
        return this.$utils.format(this.value[0]);
      }

      return `${this.$utils.format(this.value[0])} ${this.$t(
        "time-filter.and"
      )} ${this.$utils.format(this.value[1])}`;
    },
    canConfirm() {
      if (
        !(
          this.selectedDays.length === 2 &&
          this.selectedDays[0] &&
          this.selectedDays[1]
        )
      ) {
        return false;
      }

      const isFirstDateAllowed = this.allowedDates(this.selectedDays[0]);
      const isLastDateAllowed = this.allowedDates(this.selectedDays[1]);

      const isFirstTimeAvailable = this.availableFirstDateTimes.some(
        (time) =>
          time[0] === this.selectedDayTimes[0][0] &&
          time[1] === this.selectedDayTimes[0][1]
      );
      const isLastTimeAvailable = this.availableLastDateTimes.some(
        (time) =>
          time[0] === this.selectedDayTimes[1][0] &&
          time[1] === this.selectedDayTimes[1][1]
      );

      if (this.lockStartDate) {
        return isLastDateAllowed && isLastTimeAvailable;
      }

      if (
        this.selectedDays[0] === this.selectedDays[1] &&
        this.selectedDayTimes[0][0] === this.selectedDayTimes[1][0] &&
        this.selectedDayTimes[0][1] === this.selectedDayTimes[1][1]
      ) {
        return false;
      }

      return (
        isFirstDateAllowed &&
        isLastDateAllowed &&
        isFirstTimeAvailable &&
        isLastTimeAvailable
      );
    },
  },
  methods: {
    onDateChange(v) {
      if (this.lockStartDate) {
        this.$set(this, "selectedDays", [this.selectedDays[0], v]);
      } else {
        this.$set(this, "selectedDaysDatePicker", v);

        if (v.length === 2) {
          this.$set(this, "selectedDays", [...v]);
        } else {
          this.$set(this, "selectedDays", [v[0], v[0]]);
        }
      }
    },
    getAvailableTimes(selectedDay) {
      let times = [];

      // Fill with hours based on mode
      if (this.mode === "mids") {
        return [
          [this.openingHour, 0],
          [this.closingHour, 0],
        ];
      } else if (this.mode === "quarters") {
        for (let i = this.openingHour; i < this.closingHour; i++) {
          times.push([i, 0], [i, 15], [i, 30], [i, 45]);
        }
        times.push([this.closingHour, 0]);
      } else if (this.mode === "jumps") {
        times = [this.openingHour, 12, 13, this.closingHour].map((h) => [
          h % 24,
          0,
        ]);
      } else {
        // Default hourly mode
        for (let i = this.openingHour; i <= this.closingHour; i++) {
          times.push([i, 0]);
        }
      }

      // Filter out hours outside of minDate and maxDate
      return times.filter((time) => {
        const date = new Date(selectedDay);
        date.setHours(...time);

        const minDate = this.minDate;
        minDate.setMinutes(0, 0, 0);

        return date >= minDate && date <= this.maxDate;
      });
    },
    cancel() {
      this.isDatePickerVisible = false;
      this.updateDatesFromValue();
    },
    validate() {
      this.isDatePickerVisible = false;
      this.$emit("input", this.datesWithHours);
    },
    updateDatesFromValue() {
      if (this.value[0]) {
        const d1 = format(this.value[0], "yyyy-MM-dd");
        const d2 = format(this.value[1], "yyyy-MM-dd");

        const t1 = format(this.value[0], "HH:mm")
          .split(":")
          .map((e) => parseInt(e));
        const t2 = format(this.value[1], "HH:mm")
          .split(":")
          .map((e) => parseInt(e));

        this.selectedDays = [d1, d2];
        this.selectedDayTimes = [t1, t2];
      } else {
        const d1 = format(new Date(this.minDate), "yyyy-MM-dd");

        this.selectedDays = [d1, d1];
        this.selectedDaysDatePicker = [d1];

        const currentHour = new Date().getHours();
        const firstHour = Math.max(this.openingHour, currentHour);

        this.selectedDayTimes = [
          [firstHour, 0],
          [this.closingHour, 0],
        ];
      }
    },
    async loadData() {
      try {
        const orgas = await this.$api.user.organizations.get_all();
        if (orgas && orgas[0] && orgas[0].maximumDate) {
          this.maximumDays = parseInt(orgas[0].maximumDate) || 30;
        }

        const productTypes = await this.$api.user.filters.types();
        this.$set(this, "productTypes", productTypes);

        const areas = await this.$api.user.filters.areas();
        this.$set(this, "areas", Object.values(areas));

        this.updateDatesFromValue();
      } catch (err) {
        console.error("Error loading settings:", err);
      }
    },
  },

  mounted() {
    this.loadData();
    this.updateDatesFromValue();

    if (this.showOnMount) {
      this.isDatePickerVisible = true;
    }
  },
};
</script>

<style>
.dater {
  margin-left: auto;
  margin-right: auto;
}
</style>
