<template>
  <div>
    <v-card
      :class="!available ? 'disable' : ''"
      :disabled="disabled || !available"
      @click="handleClick"
      :href="href"
      :target="target"
      selectable
      :elevation="elevation ? elevation : available ? 10 : 1"
      height="100%"
      :dark="error || selected"
      :color="error ? 'error' : selected ? (color ? color : 'secondary') : ''"
    >
      <v-menu v-if="showActions" bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="action_menu" v-bind="attrs" v-on="on" @click.stop>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="to && !alert">
            <v-btn text block @click.stop="handleClick" class="justify-start">
              {{ $t("product.actions.go") }}
            </v-btn>
          </v-list-item>

          <v-list-item v-if="canExtendBooking">
            <v-btn text block @click.stop="extendBooking" class="justify-start">
              {{ $t("product.actions.extend") }}
            </v-btn>
          </v-list-item>

          <v-list-item v-if="canEndBooking">
            <v-btn text block @click.stop="endBooking" class="justify-start">
              {{ $t("product.actions.end") }}
            </v-btn>
          </v-list-item>

          <v-list-item v-if="canRegularizeOvertime">
            <v-btn
              text
              block
              @click.stop="regularizeOvertime"
              class="justify-start"
            >
              {{ $t("product.actions.regularize") }}
            </v-btn>
          </v-list-item>

          <v-list-item v-if="canReportIssue">
            <v-btn
              text
              block
              @click.stop="reportIssue"
              class="justify-start"
              color="error"
            >
              {{ $t("product.actions.report") }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-img
        v-if="!$is_mobile"
        :class="[
          disabled ? 'disabled' : '',
          selected ? 'selected' : '',
          'back_img',
        ]"
        :src="product.imageUrl"
        max-width="150px"
        width="20%"
        height="100%"
      />
      <div
        :style="
          'position:relative;z-index:1;padding-left:' +
          ($is_mobile ? '0px' : 'min(20%, 150px)')
        "
      >
        <v-card-title
          :style="'width: calc(100% ' + (closable ? '- 24px' : '') + ')'"
        >
          {{ product.label }}
        </v-card-title>
        <v-card-title class="desc" v-if="!dense">
          <v-card-subtitle v-if="available">
            {{ product.description }}
          </v-card-subtitle>
        </v-card-title>
        <v-card-text>
          <slot></slot>
        </v-card-text>
        <v-btn
          v-if="closable"
          icon
          :color="closeIconColor"
          class="close_icon"
          @click="$emit('close')"
        >
          <v-icon>mdi-{{ closeIcon ? closeIcon : "close" }}</v-icon>
        </v-btn>
      </div>

      <v-img
        v-if="$is_mobile && !dense"
        :src="product.imageUrl"
        width="50%"
        height="130px"
      />

      <v-card-title v-if="!dense && priceText">
        <div
          :class="['price', selected ? 'selected' : '']"
          :style="{
            background: $vuetify.theme.themes[theme].price,
            color: $vuetify.theme.themes[theme].footer,
          }"
        >
          {{ priceText }}
        </div>
      </v-card-title>

      <ReportIssueDialog
        v-model="showReportDialog"
        :productId="product.id"
        :orderId="orderId"
      />
    </v-card>
    <BookingAlert :show="alert" />
    <OvertimeExceededAlert :show="overtimeExceeded" />
    <RegularizeOvertimeAlert :show="!!canRegularizeOvertime" />
  </div>
</template>

<script>
import ReportIssueDialog from "./ReportIssueDialog.vue";
import BookingAlert from "./BookingAlert.vue";
import RegularizeOvertimeAlert from "./RegularizeOvertimeAlert.vue";
import OvertimeExceededAlert from "./OvertimeExceededAlert.vue";
/*
@desc Product card display
*/
export default {
  components: {
    ReportIssueDialog,
    BookingAlert,
    RegularizeOvertimeAlert,
    OvertimeExceededAlert,
  },
  props: [
    "product",
    "selected",
    "closable",
    "error",
    "color",
    "href",
    "target",
    "elevation",
    "to",
    "dense",
    "closeIcon",
    "closeIconColor",
    "disabled",
    "priceText",
    "selectable",
    "orderId",
    "alert",
    "overtimeExceeded",
    "canReportIssue",
    "canEndBooking",
    "canExtendBooking",
    "canRegularizeOvertime",
    "showActions",
  ],
  data() {
    return {
      showReportDialog: false,
    };
  },
  computed: {
    available() {
      return (
        this.product.available !== false && this.product.activated !== false
      );
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    handleClick() {
      if (this.alert) return;

      if (this.to) {
        this.$router.push(this.to);
      }
      this.$emit("click");
    },
    extendBooking() {
      this.$emit("extendBooking");
    },
    regularizeOvertime() {
      this.$emit("regularizeOvertime");
    },
    endBooking() {
      this.$emit("endBooking");
    },
    reportIssue() {
      this.showReportDialog = true;
    },
  },
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}
.desc {
  padding: 0px 20px 0px 0px !important;
  margin-top: -10px;
}
.back_img {
  position: absolute;
  z-index: 0;
  opacity: 0.9;
}
.back_img.selected {
  opacity: 0.8;
}
.price {
  position: absolute;
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  bottom: 10px !important;
  right: 10px !important;
  background: #fff;
  padding: 5px 10px;
  border-radius: 100px;
}
.price.selected {
  background: transparent;
}
.disabled {
  opacity: 0.5;
  filter: grayscale();
}
.close_icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.action_menu {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.action_menu:has(+ .close_icon) {
  right: 40px;
}
.v-card-text {
  padding: 0 16px;
}
</style>
