<template>
  <div>
    <v-row>
      <v-col
        v-for="product in sortedProducts"
        :key="product.id"
        :sm="products.length == 1 ? 12 : 6"
        cols="12"
      >
        <product
          :product="product"
          :disabled="
            hasOverlappingDates(product) || !isWithinTimeConstraints(product)
          "
          :selected="isProductInCart(product)"
          @click="$emit('select', product)"
        >
          <div
            v-if="!isWithinTimeConstraints(product)"
            class="time-constraint-warning"
          >
            {{ getTimeConstraintMessage(product) }}
          </div>
        </product>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import product from "./product.vue";
import { mapState } from "vuex";
import { hasOverlappingDates } from "../utils/hasOverlappingDates";
import {
  calculateTimeDifferenceInMinutes,
  formatDuration,
  isWithinTimeConstraints as checkTimeConstraints,
} from "../utils/duration";

/*
@desc Product list to display in search page
*/
export default {
  props: ["products", "isProductInCart", "filterDates", "types"],
  components: { product },
  computed: {
    ...mapState(["cart"]),
    /*
     * @desc Products are sorted by availability first, then by price, with alphabetical sorting for ties
     */
    sortedProducts() {
      return this.products.sort((product1, product2) => {
        const priority1 = this.calculateProductPriority(product1);
        const priority2 = this.calculateProductPriority(product2);

        if (priority1 === priority2) {
          return this.compareAlphanumeric(product1.label, product2.label);
        }

        return priority2 - priority1;
      });
    },
  },
  methods: {
    // If the user already have an existing cart, then check if the product has overlapping dates
    // If there is none, the user can select it again
    hasOverlappingDates(product) {
      return hasOverlappingDates(product, this.cart, this.filterDates);
    },

    compareAlphanumeric(str1, str2) {
      const nonAlphaRegex = /[^a-zA-Z]/g;
      const nonNumericRegex = /[^0-9]/g;

      const alphaOnly1 = str1.replace(nonAlphaRegex, "");
      const alphaOnly2 = str2.replace(nonAlphaRegex, "");

      if (alphaOnly1 === alphaOnly2) {
        const num1 = parseInt(str1.replace(nonNumericRegex, ""), 10);
        const num2 = parseInt(str2.replace(nonNumericRegex, ""), 10);
        return num1 === num2 ? 0 : num1 > num2 ? 1 : -1;
      }

      return alphaOnly1 > alphaOnly2 ? 1 : -1;
    },

    calculateProductPriority(product) {
      return product.available ? 10000 : 0;
    },

    isWithinTimeConstraints(product) {
      const typeConfig = this.types.find((t) => t.label === product.type);
      if (!typeConfig) return true;

      const timeDifference = calculateTimeDifferenceInMinutes(
        this.filterDates[0],
        this.filterDates[1]
      );

      return checkTimeConstraints(
        {
          minimumTime: typeConfig.minimumTime,
          maximumTime: typeConfig.maximumTime,
        },
        timeDifference
      );
    },

    getTimeConstraintMessage(product) {
      const typeConfig = this.types.find((t) => t.label === product.type);
      if (!typeConfig) return "";

      const timeDifference = calculateTimeDifferenceInMinutes(
        this.filterDates[0],
        this.filterDates[1]
      );

      const minTime = typeConfig.minimumTime;
      const maxTime = typeConfig.maximumTime;

      if (minTime && timeDifference < minTime) {
        return this.$t("product.timeConstraints.minDuration", {
          duration: formatDuration(minTime),
        });
      }
      if (maxTime && timeDifference > maxTime) {
        return this.$t("product.timeConstraints.maxDuration", {
          duration: formatDuration(maxTime),
        });
      }
      return "";
    },
  },
};
</script>

<style scoped>
.time-constraint-warning {
  color: #ff1744;
  font-size: 0.9em;
  margin-top: 5px;
  font-weight: bold;
}
</style>
