var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-container",style:({ background: _vm.$vuetify.theme.themes[_vm.theme].footer })},[_c('v-card',{staticClass:"inner-footer text-center",style:({ background: _vm.$vuetify.theme.themes[_vm.theme].footer }),attrs:{"flat":"","tile":"","width":"100%","dark":""}},[_c('v-row',[_c('v-col',_vm._l((_vm.footer_data),function(footer_row,index){return _c('v-card-text',{key:index,staticClass:"footer-row mb-5"},_vm._l((footer_row),function(footer_info){return _c('span',{key:footer_info.icon},[_c('v-tooltip',{attrs:{"bottom":"","disabled":!footer_info.alt},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-5 footer-data",attrs:{"color":footer_info.color,"icon":!!footer_info.href,"text":!!footer_info.text,"to":footer_info.href == ':account'
                      ? _vm.is_connected
                        ? '/account'
                        : '/connect'
                      : footer_info.href},on:{"click":function($event){footer_info.href ? _vm.go_top() : null}}},'v-btn',attrs,false),on),[_c('v-icon',{class:footer_info.text ? 'mr-3' : '',attrs:{"size":"24px"}},[_vm._v("mdi-"+_vm._s(footer_info.icon))]),_c('span',{staticStyle:{"opacity":"0.7"}},[_vm._v(_vm._s(footer_info.text))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(footer_info.alt))])])],1)}),0)}),1)],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"opacity":"0.5"}},[_vm._v(" "+_vm._s(_vm.year_display)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }