<template>
  <div>
    <v-dialog v-model="show" max-width="500px">
      <v-card>
        <v-card-title>{{ $t("product.report.title") }}</v-card-title>
        <v-card-text>
          <v-container>
            <!-- Numéro de téléphone -->
            <v-row v-if="operatorPhone">
              <v-col cols="12">
                <div class="text-h6">{{ $t("product.report.phone") }}</div>
                <div class="phone-number">{{ operatorPhone }}</div>
              </v-col>
            </v-row>

            <!-- Formulaire -->
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="selectedIssue"
                    :items="issueTypes"
                    :label="$t('product.report.issueType')"
                    required
                    :rules="[(v) => !!v || $t('requiredfield')]"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="description"
                    :label="$t('product.report.description')"
                    required
                    :rules="[(v) => !!v || $t('requiredfield')]"
                    rows="4"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="!valid"
            @click="submit"
          >
            {{ $t("product.report.send") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3000">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "ReportIssueDialog",
  props: {
    value: Boolean,
    orderId: Number,
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      operatorPhone: undefined,
      valid: false,
      loading: false,
      selectedIssue: undefined,
      description: "",
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
      issueTypes: [
        {
          text: this.$t("product.report.issues.forgotten"),
          value: "forgotten",
        },
        { text: this.$t("product.report.issues.access"), value: "access" },
        { text: this.$t("product.report.issues.end"), value: "end" },
        { text: this.$t("product.report.issues.other"), value: "other" },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  async created() {
    await this.loadOperatorPhone();
  },
  methods: {
    async loadOperatorPhone() {
      try {
        const orgas = await this.$api.user.organizations.get_all();
        if (orgas && orgas[0] && orgas[0].phoneNumber) {
          this.operatorPhone = orgas[0].phoneNumber;
        }
      } catch (error) {
        console.error("Failed to load operator phone:", error);
      }
    },
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      try {
        await this.$api.user.booking.sendIssueReport(this.orderId, {
          productId: this.productId,
          type: this.$i18n.messages.fr["product"]["report"]["issues"][
            this.selectedIssue
          ],
          description: this.description,
        });

        this.$set(this, "snackbar", {
          show: true,
          text: this.$t("product.report.success"),
          color: "success",
        });
        this.close();
      } catch (error) {
        console.error(error);
        this.$set(this, "snackbar", {
          show: true,
          text: this.$t("product.report.error"),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$refs.form.reset();
      this.$emit("input", false);
    },
  },
};
</script>

<style scoped>
.phone-number {
  font-size: 1.2em;
  font-weight: bold;
  color: #1976d2;
  margin: 8px 0;
}
</style>
