<template>
  <div>
    <v-dialog v-model="show" max-width="500px">
      <v-card>
        <v-card-title>{{ $t("product.endBooking.title") }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>{{ $t("product.endBooking.confirmMessage") }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="text-subtitle-1">
                  {{ $t("product.endBooking.usedTime") }}:
                  <span class="font-weight-bold">{{ formattedUsedTime }}</span>
                </div>
                <div class="text-subtitle-1">
                  {{ $t("product.endBooking.paidAmount") }}:
                  <span class="font-weight-bold">{{ formattedAmount }} €</span>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-alert type="warning" prominent>
                  {{
                    $t("product.endBooking.safetyWarning", {
                      interval: safetyInterval,
                    })
                  }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" :loading="loading" @click="confirmEnd">
            {{ $t("product.endBooking.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <booking-alert :show="!!error" />
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="6000">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import BookingAlert from "./BookingAlert.vue";

export default {
  name: "EndBookingDialog",
  components: {
    BookingAlert,
  },
  props: {
    value: Boolean,
    booking: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      error: undefined,
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    formattedUsedTime() {
      if (!this.booking) return "";
      const startDate = new Date(this.booking.startDate);
      const now = new Date();
      const diffMs = now - startDate;
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}m`;
    },
    formattedAmount() {
      if (!this.booking) return "";
      return (this.booking.price / 100).toFixed(2);
    },
    safetyInterval() {
      if (!this.booking) return 0;
      return this.booking.product.type.safetyInterval || 0;
    },
  },
  methods: {
    async confirmEnd() {
      this.loading = true;
      this.error = undefined;
      try {
        await this.$api.user.booking.end(this.booking.id);

        if (typeof localStorage !== "undefined") {
          const bookingCodes =
            JSON.parse(localStorage.getItem("bookingCodes")) || {};
          bookingCodes[this.booking.id] = this.booking.code;
          localStorage.setItem("bookingCodes", JSON.stringify(bookingCodes));
        }

        this.$set(this, "snackbar", {
          show: true,
          text: this.$t("product.endBooking.successMessage", {
            interval: this.safetyInterval,
          }),
          color: "success",
        });
        this.close();
        this.$emit("booking-ended");
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.error = undefined;
      this.show = false;
    },
  },
};
</script>
