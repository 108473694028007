import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/api.js";
import DatetimePicker from "vuetify-datetime-picker";
import "leaflet/dist/leaflet.css";
import mobile_detector from "./plugins/mobile_detect";
import utils from "./plugins/utils";
import VueI18n from "vue-i18n"; // Import de vue-i18n
import './plugins/api.js'

Vue.use(mobile_detector);
Vue.use(DatetimePicker);
Vue.use(utils);

Vue.config.productionTip = false;

Vue.use(VueI18n); // Utilisation de VueI18n dans Vue

// Fonction qui va récupérer les traductions au format Json dans des fichiers séparés
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

// Création d'une instance i18n exportée pour être utilisée partout dans Vue
export const i18n = new VueI18n({
  locale: navigator.language.split("-")[0],
  fallbackLocale: "fr",
  messages: loadLocaleMessages(),
});
export var from_header = false;

new Vue({
  i18n, // Utilisation de i18n
  router,
  store,
  vuetify,
  from_header,
  render: (h) => h(App),
}).$mount("#app");
