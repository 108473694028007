<template>
  <v-alert v-if="show" type="error" class="ma-0" prominent text>
    <div class="text-subtitle-1 mb-2">
      {{ $t("product.alert.message") }}
    </div>
    <div v-if="operatorPhone || operatorEmail">
      <div v-if="operatorPhone" class="phone">
        <v-icon small class="mr-1">mdi-phone</v-icon>
        {{ operatorPhone }}
      </div>
      <div v-if="operatorEmail" class="email">
        <v-icon small class="mr-1">mdi-email</v-icon>
        {{ operatorEmail }}
      </div>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "BookingAlert",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      operatorPhone: undefined,
      operatorEmail: undefined,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadOperatorInfo();
        }
      },
    },
  },
  methods: {
    async loadOperatorInfo() {
      try {
        const orgas = await this.$api.user.organizations.get_all();
        if (orgas && orgas[0]) {
          this.operatorPhone = orgas[0].phoneNumber;
          this.operatorEmail = orgas[0].mailAddress;
        }
      } catch (error) {
        console.error("Failed to load operator info:", error);
      }
    },
  },
};
</script>

<style scoped>
.phone,
.email {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
</style>
