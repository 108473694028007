<template>
  <v-container class="booking_result_container" style="text-align: center">
    <v-alert :type="is_success ? 'success' : 'error'">
      <template v-if="type === 'regularize'">
        <template v-if="is_success">
          {{ $t("booking.regularizesuccess", { interval: safetyInterval }) }}
        </template>
        <template v-else>
          <div class="text-subtitle-1 mb-2">
            {{ $t("booking.regularizefailure") }}
          </div>
          <div v-if="operatorPhone || operatorEmail">
            <div v-if="operatorPhone" class="phone">
              <v-icon small class="mr-1">mdi-phone</v-icon>
              {{ operatorPhone }}
            </div>
            <div v-if="operatorEmail" class="email">
              <v-icon small class="mr-1">mdi-email</v-icon>
              {{ operatorEmail }}
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        {{
          is_success
            ? $t("booking.extensionsuccess")
            : $t("booking.extensionfailure")
        }}
      </template>
    </v-alert>
    <v-btn
      :to="is_success ? `/orders?order_id=${order_id}` : '/orders'"
      class="mt-10"
      color="primary"
      rounded
      large
      elevation="3"
    >
      <v-icon class="mr-3">mdi-playlist-check</v-icon>
      {{ is_success ? $t("booking.seebooking") : $t("booking.gotobookings") }}
    </v-btn>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

/*
@desc Booking extension result page
*/
export default {
  data() {
    return {
      operatorPhone: undefined,
      operatorEmail: undefined,
      safetyInterval: undefined,
    };
  },
  computed: {
    /*
        @desc Result state from url args
        */
    result() {
      return this.$utils.get_url_args().result;
    },
    is_success() {
      return this.result === "success";
    },
    /*
        @desc Booking ID from url args
        */
    order_id() {
      return this.$utils.get_url_args().orderId;
    },
    booking_id() {
      return this.$utils.get_url_args().bookingId;
    },
    /*
        @desc Action type from url args
        */
    type() {
      return this.$utils.get_url_args().type;
    },
  },
  methods: {
    ...mapActions(["set_back_state"]),
    async loadOperatorInfo() {
      try {
        const orgas = await this.$api.user.organizations.get_all();
        if (orgas && orgas[0]) {
          this.operatorPhone = orgas[0].phoneNumber;
          this.operatorEmail = orgas[0].mailAddress;
        }
      } catch (error) {
        console.error("Failed to load operator info:", error);
      }
    },
    async loadSafetyInterval() {
      try {
        const booking = await this.$api.user.booking.item(this.booking_id);
        this.safetyInterval = booking.product.type.safetyInterval;
      } catch (error) {
        console.error("Failed to load safety interval:", error);
      }
    },
  },
  async mounted() {
    this.set_back_state(this.is_success ? "success" : "error");
    if (this.type === "regularize") {
      if (this.is_success) {
        await this.loadSafetyInterval();
      } else {
        await this.loadOperatorInfo();
      }
    }
  },
};
</script>

<style scoped>
.booking_result_container {
  max-width: 100%;
  width: 1000px !important;
}
.phone,
.email {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
</style>
