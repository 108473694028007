import Vue from 'vue'

// ----------------------------------------------------- MAIN API CLASS

class APIS {

    constructor() {
        this.credentials = {
     "tmp_jwt": "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY0NTM1NTc5MX0.YeTbwv_XOGUFPW_yfPlmzh_Ca2tDnHpM4Zk0Sgi1uMpBowaMZ4eBZWgPxfkICRuGtuCdQOqvGXERhLUgNjYxLg"
 }
    }

    // ----------------- INSTALLER

    install() {
        const apis = this
        Vue.mixin({
            beforeCreate() {
                this.$api = apis
            },
        })
    }


    // ----------------- UTILS

    __get_cookie(key) {
        return Object.fromEntries(document.cookie.split('; ').map(cd => cd.split('=')))[key]
    }

    // ----------------- INNER API CALLERS
    
    
    
    async __manager_orgas_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_manager", "organizations", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    
    async __products_indispos_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_manager", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    async __products_activation_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_manager", "products", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    
    async __manager_areas_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_manager", "areas", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    async __manager_resas_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_manager", "reservations", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    
    
    async __auth_password_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    async __auth_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    
    async __user_organizations_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_user", "organizations", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    async __user_filters_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_user", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    
    async __user_booking_api(endpoint, method, data, headers, data_format = 'json') {
        headers = headers ?? {}
        const options = { method, headers }
        if (data) {
            headers['Content-type'] = {
                'json': 'application/json',
                'text': 'text/plain'
            }[data_format] ?? data_format
            options.body = data_format == 'json' ? JSON.stringify(data) : data
        }
        const url = [process.env.VUE_APP_API_HOST + "/api/_user", endpoint].join('/')
        const resp = await fetch(url, options)
        if (!resp.ok) throw new Error(`response error ${resp.status} calling ${url} "${resp.statusText}"`)
        if (resp.headers.get('content-type') == 'application/json') return await resp.json()
        return await resp.text()
    }
    
    // ----------------- EXTERNAL CALLERS
    
    manager = {
        
        
        orgas: {
            mine: () => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__manager_orgas_api("", "GET", null, headers, null)
            },
            update: (id, data = null) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__manager_orgas_api(id, "PUT", data, headers, "json")
            },
            
        },
        
        products: {
            
            
            indispos: {
                get_all: (id) => {
                    const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                    return this.__products_indispos_api("reservations/product/" + id, "GET", null, headers, null)
                },
                create: (id, data = null) => {
                    const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                    return this.__products_indispos_api("reservations/product/" + id, "POST", data, headers, "json")
                },
                delete: (id) => {
                    const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                    return this.__products_indispos_api("reservations/" + id, "DELETE", null, headers, null)
                },
                
            },
            
            activation: {
                activate: (id) => {
                    const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                    return this.__products_activation_api(id + "/activate", "GET", null, headers, null)
                },
                deactivate: (id) => {
                    const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                    return this.__products_activation_api(id + "/deactivate", "GET", null, headers, null)
                },
                
            },
        },
        
        areas: {
            activate: (id) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__manager_areas_api(id + "/activate", "GET", null, headers, null)
            },
            deactivate: (id) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__manager_areas_api(id + "/deactivate", "GET", null, headers, null)
            },
            
        },
        
        resas: {
            delete: (id) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__manager_resas_api("user/" + id, "DELETE", null, headers, null)
            },
            
        },
    }
    
    auth = {
        activate: (key) => {
            const headers = null
            return this.__auth_api("activate?key=" + key, "GET", null, headers, null)
        },
        authenticate: (data = null) => {
            const headers = null
            return this.__auth_api("authenticate", "POST", data, headers, "json")
        },
        get: () => {
            const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
            return this.__auth_api("account", "GET", null, headers, null)
        },
        register: (data = null) => {
            const headers = null
            return this.__auth_api("register", "POST", data, headers, "json")
        },
        update: (data = null) => {
            const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
            return this.__auth_api("account", "POST", data, headers, "json")
        },
        update_password: (data = null) => {
            const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
            return this.__auth_api("/account/change-password", "POST", data, headers, "json")
        },
        
        password: {
            ask_reset: (data = null) => {
                const headers = null
                return this.__auth_password_api("account/reset-password/init", "POST", data, headers, "text")
            },
            reset: (data = null) => {
                const headers = null
                return this.__auth_password_api("account/reset-password/finish", "POST", data, headers, "json")
            },
            
        },
    }
    
    user = {
        
        
        organizations: {
            get_all: () => {
                const headers = null
                return this.__user_organizations_api("", "GET", null, headers, null)
            },
            contact: (id, data = null) => {
                const headers = null
                return this.__user_organizations_api(id + "/contact", "POST", data, headers, "json")
            },
            
        },
        
        filters: {
            areas: (type_id = "", start_date = "", end_date = "") => {
                const headers = null
                return this.__user_filters_api("areas?typeId=" + type_id + "&startDate=" + start_date + "&endDate=" + end_date, "GET", null, headers, null)
            },
            types: () => {
                const headers = null
                return this.__user_filters_api("product-types", "GET", null, headers, null)
            },
            
        },
        
        booking: {
            stripe_portal: (data = null) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("orders/portal", "POST", data, headers, "json")
            },
            all: (page) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("orders?page=" + page, "GET", null, headers, null)
            },
            item: (id) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("reservations/" + id, "GET", null, headers, null)
            },
            order: (data = null) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("orders", "POST", data, headers, "json")
            },
            delete: (id) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("orders/" + id, "DELETE", null, headers, null)
            },
            end: (id) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("reservations/" + id + "/end", "POST", null, headers, null)
            },
            extend: (id, data = null) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("reservations/" + id + "/extend", "POST", data, headers, "json")
            },
            regularizeOvertime: (id, data = null) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("reservations/" + id + "/regularize-overtime", "POST", data, headers, "json")
            },
            sendIssueReport: (id, data = null) => {
                const headers = { "Authorization": "Bearer " + (localStorage.getItem("account-token") ?? sessionStorage.getItem("account-token")) }
                return this.__user_booking_api("orders/" + id + "/issues", "POST", data, headers, "json")
            },
            
        },
    }
}

// ----------------------------------------------------- VUE INSTALL

const apis = new APIS()
Vue.use(apis)