var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.orders && !_vm.loading)?_c('v-container',{staticClass:"ordersContainer"},[(!_vm.haveOrders)?_c('span',{staticClass:"watermark"},[_c('h1',[_vm._v(_vm._s(_vm.$t("orders.noorder")))]),_c('v-btn',{staticClass:"mt-10",attrs:{"to":"/","color":"primary","fab":"","large":"","elevation":"3"}},[_c('v-icon',[_vm._v("mdi-home")])],1)],1):_vm._l((_vm.filteredOrdersList),function(orders,status){return _c('v-card',{key:status,staticClass:"mb-10",attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-5"},[_vm._v("mdi-"+_vm._s(_vm.iconsPerOrderStatus[status]))]),_vm._v(_vm._s(_vm.$t(("orders." + status)))+" ")],1),_c('v-expansion-panels',{model:{value:(_vm.expandedOrderIndexPerStatus[status]),callback:function ($$v) {_vm.$set(_vm.expandedOrderIndexPerStatus, status, $$v)},expression:"expandedOrderIndexPerStatus[status]"}},_vm._l((orders),function(order,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',{attrs:{"id":'order_' + order.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c('v-card-title',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("orders.order"))+" "),_c('span',{staticStyle:{"opacity":"0.7"}},[_vm._v(_vm._s(order.id))]),_vm._v(" du "+_vm._s(_vm.$utils.format(order.createdDate))+"   "),_c('b',[_vm._v(_vm._s(order.items.length)+" "+_vm._s(_vm.$tc("orders.booking", order.items.length)))]),_vm._l((order.items),function(item,index){return (!open)?_c('div',{key:index,staticStyle:{"font-size":"15px","font-weight":"normal"}},[_vm._v("  "+_vm._s(item.label)),_c('span',[_vm._v(_vm._s(_vm.getTerminatedBookingCode(item)))]),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.getBookingOvertime(item)))]),_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.getBookingExtension(item)))])]):_vm._e()})],2)]}}],null,true)}),_c('v-expansion-panel-content',[_c('v-list',_vm._l((order.items),function(item,index){return _c('div',{key:index,staticClass:"mb-10"},[_vm._v(" "+_vm._s(item.label)),_c('span',[_vm._v(_vm._s(_vm.getTerminatedBookingCode(item)))]),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.getBookingOvertime(item)))]),_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(_vm.getBookingExtension(item)))]),_c('product',{staticClass:"ma-3",attrs:{"canExtendBooking":_vm.canExtendBooking(item, order),"canEndBooking":_vm.canEndBooking(item, order),"canRegularizeOvertime":_vm.canRegularizeOvertime(item),"orderId":order.id,"showActions":true,"canReportIssue":true,"alert":(status == 'onGoing' || status == 'future') &&
                      order.status == 'paid' &&
                      !_vm.$utils.time_is_in_past(item.startDate) &&
                      !_vm.isCodeValid(item),"overtimeExceeded":_vm.isOvertimeExceeded(item),"product":item.product,"selected":_vm.isPaidAndImportant(order) && !_vm.isRefunded(item),"selectable":_vm.isPaidAndImportant(order) && !_vm.isRefunded(item),"color":_vm.isPaidAndImportant(order) ? 'primary' : '',"elevation":_vm.isPaidAndImportant(order) && !_vm.isRefunded(item) ? 10 : 2,"disabled":_vm.isRefunded(item),"to":(!!_vm.getTerminatedBookingCode(item) ||
                        _vm.isPaidAndImportant(order)) &&
                      !_vm.isRefunded(item)
                        ? '/reservation/?item_id=' + item.id
                        : undefined,"priceText":_vm.isRefunded(item)
                        ? _vm.$t('refunded')
                        : (item.price / 100).toFixed(2) + ' €'},on:{"extendBooking":function($event){return _vm.extendBooking(item, order.id)},"endBooking":function($event){return _vm.endBooking(item)},"regularizeOvertime":function($event){return _vm.regularizeOvertime(item, order.id)}}})],1)}),0),_c('v-card-title',{staticStyle:{"font-size":"30px"}},[_c('v-divider',{staticClass:"mr-5"}),_vm._v(_vm._s(_vm.getOrderPrice(order))+" € ")],1),(_vm.cancelErrorsPerOrderId[order.id])?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.cancelErrorsPerOrderId[order.id]))]):_vm._e(),_c('v-card-title',[(_vm.isCancelable(order))?_c('v-btn',{attrs:{"right":"","color":"error","text":"","large":"","disabled":_vm.orderToDelete == order.id,"loading":_vm.orderToDelete == order.id},on:{"click":function($event){_vm.orderToCancel = order}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t("orders.cancelorder"))+" ")],1):_vm._e(),_c('v-spacer'),(_vm.getOrderActions(order).length)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":_vm.getOrderActions(order)[0].url,"target":"_blank","color":"primary","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-receipt")]),_vm._v(_vm._s(_vm.getStripeBtnText(order))+" ")],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.getOrderActions(order)),function(action){return _c('v-list-item',{key:action.url},[_c('v-btn',{attrs:{"href":action.url,"target":"_blank","text":""}},[_vm._v(_vm._s(action.name)+" ")])],1)}),1)],1):_vm._e()],1)],1)],1)}),1)],1)}),_c('v-dialog',{attrs:{"value":!!_vm.orderToCancel,"max-width":"400"},on:{"input":function($event){_vm.orderToCancel = undefined}}},[(_vm.orderToCancel)?_c('v-card',{staticClass:"pa-5"},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("orders.cancelorder"))+" "),_c('u',[_vm._v(_vm._s(_vm.orderToCancel.id))]),_vm._v(" ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.orderToCancel = undefined}}},[_vm._v(" "+_vm._s(_vm.$t("orders.canceltext"))+" ")]),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.deleteOrder(_vm.orderToCancel.id);
              _vm.orderToCancel = undefined;}}},[_vm._v(" "+_vm._s(_vm.$t("orders.cancelorder"))+" ")])],1)],1):_vm._e()],1)],2):_c('v-container',[_c('v-skeleton-loader',{attrs:{"height":"1000px","type":"list-item@5","loading":true}})],1),_c('end-booking-dialog',{attrs:{"booking":_vm.bookingToEnd},on:{"booking-ended":_vm.reloadOrders},model:{value:(_vm.showEndBookingDialog),callback:function ($$v) {_vm.showEndBookingDialog=$$v},expression:"showEndBookingDialog"}}),_c('extend-booking-dialog',{attrs:{"booking":_vm.bookingToExtend},model:{value:(_vm.showExtendBookingDialog),callback:function ($$v) {_vm.showExtendBookingDialog=$$v},expression:"showExtendBookingDialog"}}),_c('regularize-overtime-dialog',{attrs:{"booking":_vm.bookingToRegularize},model:{value:(_vm.showRegularizeOvertimeDialog),callback:function ($$v) {_vm.showRegularizeOvertimeDialog=$$v},expression:"showRegularizeOvertimeDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }