<template>
  <div>
    <v-container class="ordersContainer" v-if="orders && !loading">
      <span class="watermark" v-if="!haveOrders">
        <h1>{{ $t("orders.noorder") }}</h1>
        <v-btn to="/" class="mt-10" color="primary" fab large elevation="3">
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </span>
      <template v-else>
        <v-card
          v-for="(orders, status) in filteredOrdersList"
          :key="status"
          elevation="0"
          class="mb-10"
        >
          <v-card-title>
            <v-icon class="mr-5">mdi-{{ iconsPerOrderStatus[status] }}</v-icon
            >{{ $t(`orders.${status}`) }}
          </v-card-title>
          <v-expansion-panels v-model="expandedOrderIndexPerStatus[status]">
            <v-expansion-panel v-for="(order, index) in orders" :key="index">
              <v-expansion-panel-header
                v-slot="{ open }"
                :id="'order_' + order.id"
              >
                <v-card-title class="mb-2">
                  {{ $t("orders.order") }}&nbsp;<span style="opacity: 0.7">{{
                    order.id
                  }}</span
                  >&nbsp;du
                  {{ $utils.format(order.createdDate) }} &nbsp;&nbsp;<b
                    >{{ order.items.length }}
                    {{ $tc("orders.booking", order.items.length) }}</b
                  >
                  <div
                    v-for="(item, index) in order.items"
                    v-if="!open"
                    :key="index"
                    style="font-size: 15px; font-weight: normal"
                  >
                    &emsp;{{ item.label
                    }}<span>{{ getTerminatedBookingCode(item) }}</span
                    ><span class="red--text">{{
                      getBookingOvertime(item)
                    }}</span>
                    <span class="green--text">{{
                      getBookingExtension(item)
                    }}</span>
                  </div>
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-list>
                  <div
                    v-for="(item, index) in order.items"
                    :key="index"
                    class="mb-10"
                  >
                    {{ item.label
                    }}<span>{{ getTerminatedBookingCode(item) }}</span
                    ><span class="red--text">{{
                      getBookingOvertime(item)
                    }}</span>
                    <span class="green--text">{{
                      getBookingExtension(item)
                    }}</span>
                    <product
                      :canExtendBooking="canExtendBooking(item, order)"
                      @extendBooking="extendBooking(item, order.id)"
                      :canEndBooking="canEndBooking(item, order)"
                      @endBooking="endBooking(item)"
                      :canRegularizeOvertime="canRegularizeOvertime(item)"
                      @regularizeOvertime="regularizeOvertime(item, order.id)"
                      :orderId="order.id"
                      :showActions="true"
                      :canReportIssue="true"
                      :alert="
                        (status == 'onGoing' || status == 'future') &&
                        order.status == 'paid' &&
                        !$utils.time_is_in_past(item.startDate) &&
                        !isCodeValid(item)
                      "
                      :overtimeExceeded="isOvertimeExceeded(item)"
                      :product="item.product"
                      :selected="isPaidAndImportant(order) && !isRefunded(item)"
                      :selectable="
                        isPaidAndImportant(order) && !isRefunded(item)
                      "
                      class="ma-3"
                      :color="isPaidAndImportant(order) ? 'primary' : ''"
                      :elevation="
                        isPaidAndImportant(order) && !isRefunded(item) ? 10 : 2
                      "
                      :disabled="isRefunded(item)"
                      :to="
                        (!!getTerminatedBookingCode(item) ||
                          isPaidAndImportant(order)) &&
                        !isRefunded(item)
                          ? '/reservation/?item_id=' + item.id
                          : undefined
                      "
                      :priceText="
                        isRefunded(item)
                          ? $t('refunded')
                          : (item.price / 100).toFixed(2) + ' €'
                      "
                    ></product>
                  </div>
                </v-list>
                <v-card-title style="font-size: 30px">
                  <v-divider class="mr-5"></v-divider
                  >{{ getOrderPrice(order) }} €
                </v-card-title>
                <v-alert v-if="cancelErrorsPerOrderId[order.id]" type="error">{{
                  cancelErrorsPerOrderId[order.id]
                }}</v-alert>
                <v-card-title>
                  <v-btn
                    v-if="isCancelable(order)"
                    right
                    color="error"
                    text
                    large
                    @click="orderToCancel = order"
                    :disabled="orderToDelete == order.id"
                    :loading="orderToDelete == order.id"
                  >
                    <v-icon class="mr-3">mdi-delete</v-icon>
                    {{ $t("orders.cancelorder") }}
                  </v-btn>
                  <v-spacer />

                  <!-- ---------------------------------------- URL BTNS -->

                  <v-menu v-if="getOrderActions(order).length">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="getOrderActions(order)[0].url"
                        target="_blank"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        text
                      >
                        <v-icon class="mr-3">mdi-receipt</v-icon
                        >{{ getStripeBtnText(order) }}
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="action in getOrderActions(order)"
                        :key="action.url"
                      >
                        <v-btn :href="action.url" target="_blank" text
                          >{{ action.name }}
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </template>
      <v-dialog
        :value="!!orderToCancel"
        @input="orderToCancel = undefined"
        max-width="400"
      >
        <v-card v-if="orderToCancel" class="pa-5">
          <v-card-title class="text-h5">
            {{ $t("orders.cancelorder") }}&nbsp;<u>{{ orderToCancel.id }}</u> ?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="orderToCancel = undefined">
              {{ $t("orders.canceltext") }}
            </v-btn>
            <v-btn
              color="error"
              text
              @click="
                deleteOrder(orderToCancel.id);
                orderToCancel = undefined;
              "
            >
              {{ $t("orders.cancelorder") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container v-else>
      <v-skeleton-loader
        height="1000px"
        type="list-item@5"
        :loading="true"
      ></v-skeleton-loader>
    </v-container>
    <end-booking-dialog
      v-model="showEndBookingDialog"
      :booking="bookingToEnd"
      @booking-ended="reloadOrders"
    />
    <extend-booking-dialog
      v-model="showExtendBookingDialog"
      :booking="bookingToExtend"
    />
    <regularize-overtime-dialog
      v-model="showRegularizeOvertimeDialog"
      :booking="bookingToRegularize"
    />
  </div>
</template>

<script>
import product from "../components/product.vue";
import EndBookingDialog from "../components/EndBookingDialog.vue";
import ExtendBookingDialog from "../components/ExtendBookingDialog.vue";
import RegularizeOvertimeDialog from "../components/RegularizeOvertimeDialog.vue";
import { mapActions } from "vuex";
/*
@desc Full orders user page
*/
export default {
  components: {
    product,
    EndBookingDialog,
    ExtendBookingDialog,
    RegularizeOvertimeDialog,
  },
  data: () => ({
    orders: [],
    orderToDelete: undefined,
    orderToCancel: undefined,
    cancelErrorsPerOrderId: {},
    orderIdToScrollTo: undefined,
    loading: true,
    showEndBookingDialog: false,
    showExtendBookingDialog: false,
    showRegularizeOvertimeDialog: false,
    bookingToEnd: undefined,
    bookingToExtend: undefined,
    bookingToRegularize: undefined,
    expandedOrderIndexPerStatus: {
      future: undefined,
      onGoing: undefined,
      past: undefined,
      canceled: undefined,
    },
  }),
  watch: {
    /*
        @desc Check for new order to trigger the "which one need to be opened" checker
        */
    orders: {
      handler() {
        if (this.orderIdToScrollTo) {
          setTimeout(() => this.goToOrder(this.orderIdToScrollTo), 500);
        }
      },
      deep: true,
    },
  },
  computed: {
    /*
        @desc Orders filtered by items (no item orders are not shown)
        */
    filteredOrdersList() {
      const futureOrders = [];
      const onGoingOrders = [];
      const pastOrders = [];
      const canceledOrders = [];

      for (const order of this.orders) {
        if (order.status == "billed") {
          futureOrders.push(order);
        } else if (order.status == "paid") {
          if (this.$utils.time_is_in_past(order.items[0]?.startDate)) {
            if (
              order.items.find((i) => i.code || this.isDuringSafetyInterval(i))
            ) {
              onGoingOrders.push(order);
            } else {
              pastOrders.push(order);
            }
          } else {
            futureOrders.push(order);
          }
        } else {
          canceledOrders.push(order);
        }
      }

      const ordersPerStatus = {
        future: futureOrders,
        onGoing: onGoingOrders,
        past: pastOrders,
        canceled: canceledOrders,
      };

      return Object.fromEntries(
        Object.entries(ordersPerStatus).filter(([, o]) => o.length)
      );
    },
    haveOrders() {
      return !!this.orders.length;
    },
    iconsPerOrderStatus() {
      return {
        future: "star-shooting",
        onGoing: "clipboard-text-clock",
        past: "check-bold",
        canceled: "clipboard-off",
      };
    },
  },
  methods: {
    ...mapActions(["set_back_state"]),
    isPaidAndImportant(order) {
      return (
        order.status == "paid" &&
        [
          ...(this.filteredOrdersList?.future ?? []),
          ...(this.filteredOrdersList?.onGoing ?? []),
        ].find((o) => o.id == order.id)
      );
    },
    isRefunded(item) {
      return item.status == "refunded";
    },
    isCancelable(order) {
      return (
        !this.$utils.time_is_in_past(order.items[0].startDate) &&
        !(order.status == "refunded" || order.status == "canceled")
      );
    },
    isCodeValid(item) {
      return item.code != null && item.code != "" && item.code != undefined;
    },
    canEndBooking(booking, order) {
      return (
        order.status == "paid" &&
        this.isCodeValid(booking) &&
        !this.isRefunded(booking) &&
        this.$utils.time_is_in_past(booking.startDate) &&
        !this.$utils.time_is_in_past(booking.endDate)
      );
    },
    canExtendBooking(booking, order) {
      return (
        order.status == "paid" &&
        this.isCodeValid(booking) &&
        !this.isRefunded(booking) &&
        !this.$utils.time_is_in_past(booking.endDate)
      );
    },
    canRegularizeOvertime(booking) {
      return (
        this.isCodeValid(booking) &&
        !this.isRefunded(booking) &&
        this.$utils.time_is_in_past(booking.endDate) &&
        !this.isOvertimeExceeded(booking)
      );
    },
    isOvertimeExceeded(booking) {
      if (!this.isCodeValid(booking)) return false;

      const endDate = new Date(booking.endDate);
      const now = new Date();
      const exceedingMinutes = Math.floor((now - endDate) / (1000 * 60));
      const exceededHours = Math.ceil(exceedingMinutes / 60.0);

      const overchargeRules = JSON.parse(booking.product.type.overcharge);

      const { pricePerHour } = overchargeRules;

      return (
        pricePerHour.length === 0 ||
        exceededHours > pricePerHour[pricePerHour.length - 1][0]
      );
    },
    /*
        @desc Compute full order price (take into account refunded items)
        */
    getOrderPrice(order) {
      return (
        order.items
          .map((item) => (item.status != "refunded" ? item.price : 0))
          .reduce((a, b) => a + b, 0) / 100
      ).toFixed(2);
    },
    isDuringSafetyInterval(booking) {
      // If not terminated, return
      if (
        new Date(booking.endDate).getTime() >=
          new Date(booking.initialEndDate).getTime() &&
        !this.getBookingOvertime(booking)
      ) {
        return false;
      }

      // Check if still in safeInterval
      if (
        new Date(booking.endDate).getTime() +
          booking.product.type.safetyInterval * 60000 <
        new Date().getTime()
      ) {
        return false;
      }

      return true;
    },
    getTerminatedBookingCode(booking) {
      if (!this.isDuringSafetyInterval(booking)) return "";

      if (typeof localStorage !== "undefined") {
        const bookingCodes =
          JSON.parse(localStorage.getItem("bookingCodes")) || {};

        if (!bookingCodes[booking.id]) return "";

        return ` - Code d'accès : ${bookingCodes[booking.id]}`;
      }

      return "";
    },
    async deleteOrder(id) {
      this.$delete(this.cancelErrorsPerOrderId, id);
      try {
        this.orderToDelete = id;
        await this.$api.user.booking.delete(id);
        this.orderToDelete = undefined;
        this.reloadOrders();
      } catch (e) {
        this.cancelErrorsPerOrderId[id] = this.$t("orders.cancelerror");
        this.orderToDelete = undefined;
      }
    },
    /*
        @desc Open specific order in UI
        @arg1 order ID
        @arg2 section under which to find the order
        */
    goToOrder(orderId) {
      const status = Object.keys(this.filteredOrdersList).find((key) =>
        this.filteredOrdersList[key].find((o) => o.id == orderId)
      );

      const index = this.filteredOrdersList[status]
        .map((order) => order.id + "")
        .indexOf(orderId + "");

      this.$set(this, "expandedOrderIndexPerStatus", {
        ...this.expandedOrderIndexPerStatus,
        [status]: index,
      });

      setTimeout(
        () =>
          document
            .getElementById("order_" + orderId)
            ?.scrollIntoView({ behavior: "smooth" }),
        500
      );
    },
    getStripeBtnText(order) {
      return (
        {
          billed: this.$t("orders.pay"),
          paid: this.$t("orders.receipt"),
          refunded: this.$t("orders.refundingreceipt"),
        }[order.status] ?? this.$t("orders.receipt")
      );
    },
    getOrderActions(order) {
      const invoices = order.invoices ?? [];
      const actions = invoices.map((url, i) => ({
        name: this.$t("orders.invoice", { number: i + 1 }),
        url,
      }));

      const stripeUrl = order.stripeUrl;
      if (stripeUrl && stripeUrl.includes("checkout.stripe.com")) {
        actions.push({ name: this.$t("orders.pay"), url: stripeUrl });
      }

      return actions;
    },
    endBooking(item) {
      this.bookingToEnd = item;
      this.showEndBookingDialog = true;
    },
    extendBooking(item, orderId) {
      this.bookingToExtend = { ...item, orderId };
      this.showExtendBookingDialog = true;
    },
    regularizeOvertime(item, orderId) {
      this.bookingToRegularize = { ...item, orderId };
      this.showRegularizeOvertimeDialog = true;
    },
    getBookingOvertime(booking) {
      const overtime =
        new Date(booking.endDate).getTime() -
        new Date(booking.initialEndDate).getTime();

      if (!overtime || overtime < 60000) {
        return "";
      }

      const hrs = Math.floor(overtime / (1000 * 60 * 60));
      const mins = Math.floor((overtime % (1000 * 60 * 60)) / (1000 * 60));

      return ` ${this.$t("orders.overtime", { hrs, mins })}`;
    },
    getBookingExtension(booking) {
      const extension =
        new Date(booking.endDate).getTime() -
        new Date(booking.endDateBeforeExtension).getTime();

      if (!extension || extension < 60000) {
        return "";
      }

      const hrs = Math.floor(extension / (1000 * 60 * 60));
      const mins = Math.floor((extension % (1000 * 60 * 60)) / (1000 * 60));

      return ` ${this.$t("orders.extension", { hrs, mins })}`;
    },
    /*
        @desc Load full orders array from API
        */
    reloadOrders() {
      this.loading = true;
      this.$utils
        .get_paged_data((page) => this.$api.user.booking.all(page))
        .then((orders) => {
          this.loading = false;
          const final_orders = this.$utils.sort_by_date(
            orders
              .filter((o) => o.items.length)
              .map((o) => {
                const itemsStatus = o.items
                  .map((i) => i.status)
                  .filter((e, i, s) => s.indexOf(e) == i);
                if (itemsStatus.length == 1 && itemsStatus[0] == "refunded") {
                  o.status = "refunded";
                }

                return o;
              }),
            "items.0.startDate"
          );

          this.$set(this, "orders", final_orders);
          if (this.filteredOrdersList.future?.length == 1) {
            this.orderIdToScrollTo = this.filteredOrdersList.future[0].id;
          }
          this.set_back_state(this.haveOrders ? "contains" : "empty");
        });
    },
  },
  /*
    @desc Launch full orders loading method `reloadOrders`
    */
  mounted() {
    this.reloadOrders();
    this.orderIdToScrollTo = this.$utils.get_url_args().order_id;
  },
};
</script>

<style>
.ordersContainer {
  max-width: 100%;
  width: 1000px !important;
}
</style>
