var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{class:!_vm.available ? 'disable' : '',attrs:{"disabled":_vm.disabled || !_vm.available,"href":_vm.href,"target":_vm.target,"selectable":"","elevation":_vm.elevation ? _vm.elevation : _vm.available ? 10 : 1,"height":"100%","dark":_vm.error || _vm.selected,"color":_vm.error ? 'error' : _vm.selected ? (_vm.color ? _vm.color : 'secondary') : ''},on:{"click":_vm.handleClick}},[(_vm.showActions)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"action_menu",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3328296654)},[_c('v-list',[(_vm.to && !_vm.alert)?_c('v-list-item',[_c('v-btn',{staticClass:"justify-start",attrs:{"text":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("product.actions.go"))+" ")])],1):_vm._e(),(_vm.canExtendBooking)?_c('v-list-item',[_c('v-btn',{staticClass:"justify-start",attrs:{"text":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.extendBooking.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("product.actions.extend"))+" ")])],1):_vm._e(),(_vm.canEndBooking)?_c('v-list-item',[_c('v-btn',{staticClass:"justify-start",attrs:{"text":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.endBooking.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("product.actions.end"))+" ")])],1):_vm._e(),(_vm.canRegularizeOvertime)?_c('v-list-item',[_c('v-btn',{staticClass:"justify-start",attrs:{"text":"","block":""},on:{"click":function($event){$event.stopPropagation();return _vm.regularizeOvertime.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("product.actions.regularize"))+" ")])],1):_vm._e(),(_vm.canReportIssue)?_c('v-list-item',[_c('v-btn',{staticClass:"justify-start",attrs:{"text":"","block":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.reportIssue.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("product.actions.report"))+" ")])],1):_vm._e()],1)],1):_vm._e(),(!_vm.$is_mobile)?_c('v-img',{class:[
        _vm.disabled ? 'disabled' : '',
        _vm.selected ? 'selected' : '',
        'back_img' ],attrs:{"src":_vm.product.imageUrl,"max-width":"150px","width":"20%","height":"100%"}}):_vm._e(),_c('div',{style:('position:relative;z-index:1;padding-left:' +
        (_vm.$is_mobile ? '0px' : 'min(20%, 150px)'))},[_c('v-card-title',{style:('width: calc(100% ' + (_vm.closable ? '- 24px' : '') + ')')},[_vm._v(" "+_vm._s(_vm.product.label)+" ")]),(!_vm.dense)?_c('v-card-title',{staticClass:"desc"},[(_vm.available)?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.product.description)+" ")]):_vm._e()],1):_vm._e(),_c('v-card-text',[_vm._t("default")],2),(_vm.closable)?_c('v-btn',{staticClass:"close_icon",attrs:{"icon":"","color":_vm.closeIconColor},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-"+_vm._s(_vm.closeIcon ? _vm.closeIcon : "close"))])],1):_vm._e()],1),(_vm.$is_mobile && !_vm.dense)?_c('v-img',{attrs:{"src":_vm.product.imageUrl,"width":"50%","height":"130px"}}):_vm._e(),(!_vm.dense && _vm.priceText)?_c('v-card-title',[_c('div',{class:['price', _vm.selected ? 'selected' : ''],style:({
          background: _vm.$vuetify.theme.themes[_vm.theme].price,
          color: _vm.$vuetify.theme.themes[_vm.theme].footer,
        })},[_vm._v(" "+_vm._s(_vm.priceText)+" ")])]):_vm._e(),_c('ReportIssueDialog',{attrs:{"productId":_vm.product.id,"orderId":_vm.orderId},model:{value:(_vm.showReportDialog),callback:function ($$v) {_vm.showReportDialog=$$v},expression:"showReportDialog"}})],1),_c('BookingAlert',{attrs:{"show":_vm.alert}}),_c('OvertimeExceededAlert',{attrs:{"show":_vm.overtimeExceeded}}),_c('RegularizeOvertimeAlert',{attrs:{"show":!!_vm.canRegularizeOvertime}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }