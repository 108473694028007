<template>
  <div style="position: relative">
    <v-row>
      <v-col
        v-for="productType in productTypes"
        :key="productType.type"
        :sm="products.length === 1"
        cols="12"
      >
        <div class="product-container">
          <div class="product-image">
            <img :src="productType.imageUrl" :alt="productType.type" />
          </div>
          <div class="product-details">
            <div class="title-price">
              <h1>{{ productType.type }}</h1>
              <p class="price">
                {{ getPrice(productType) + " €" }}
              </p>
            </div>
            <p class="information">
              {{ $t("product-withoutlist.available") }}
              {{ availableProductsCountPerType(productType.type) }}/{{
                getProductsPerType(productType.type).length
              }}
            </p>
            <p
              v-if="!isWithinTimeConstraints(productType.type)"
              class="time-constraint-warning"
            >
              {{ getTimeConstraintMessage(productType.type) }}
            </p>
            <v-btn
              v-if="showAddToCartButton(productType.type)"
              type="checkbox"
              color="secondary"
              class="cart-button"
              :disabled="!isWithinTimeConstraints(productType.type)"
              @click="
                $emit('select', getSelectedProductFromType(productType.type))
              "
            >
              <p class="button-text">
                <v-icon class="mr-3 ml-0">mdi-plus</v-icon>
                {{ $t("product-withoutlist.addcart") }}
              </p>
            </v-btn>

            <v-btn
              v-else-if="showRemoveFromCartButton(productType.type)"
              type="checkbox"
              color="error"
              class="cart-button"
              @click="
                $emit('select', getSelectedProductFromType(productType.type))
              "
            >
              <p class="button-text">
                <v-icon class="mr-3 ml-0">mdi-minus</v-icon>
                {{ $t("product-withoutlist.removecart") }}
              </p>
            </v-btn>

            <v-btn
              v-else-if="!availableProductsCountPerType(productType.type)"
              type="checkbox"
              color="primary"
              class="cart-button"
              disabled
            >
              <p class="button-text">
                {{ $t("product-withoutlist.unavailable") }}
              </p>
            </v-btn>

            <v-btn
              v-else
              type="checkbox"
              color="primary"
              class="cart-button"
              disabled
            >
              <p class="button-text">
                {{ $t("product-withoutlist.incart") }}
              </p>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import product from "./product.vue";
import { mapState } from "vuex";
import { hasOverlappingDates } from "../utils/hasOverlappingDates";
import { findOptimalProduct } from "../utils/productSelection";
import {
  calculateTimeDifferenceInMinutes,
  formatDuration,
  isWithinTimeConstraints as checkTimeConstraints,
} from "../utils/duration";
import { getReservationPrice } from "../utils/getReservationPrice";

/*
@desc Product list to display in search page
*/
export default {
  props: ["products", "isProductInCart", "filterDates", "types"],
  components: { product },
  computed: {
    ...mapState(["cart"]),

    productTypes() {
      const typesSet = new Set();

      for (const product of this.products) {
        typesSet.add(product.type);
      }

      const productTypes = Array.from(typesSet).map((type) => {
        const productOfType = this.products.find(
          (product) => product.type === type
        );

        const productType = this.types.find((t) => t.label === type);

        return {
          type,
          imageUrl: productOfType.imageUrl,
          price: productType.price,
        };
      });

      return productTypes;
    },
    hours() {
      return (this.filterDates[1] - this.filterDates[0]) / 3600;
    },
  },

  methods: {
    getPrice(type) {
      return getReservationPrice(this.hours / 1000, type.price);
    },

    getSelectedProductFromType(type) {
      const availableProducts = this.getProductsPerType(type).filter(
        (product) => product.activated && product.available
      );

      const reservedProducts = this.getProductsPerType(type).filter(
        (product) => product.activated && !product.available
      );

      if (this.shouldOrderByWeight(type)) {
        const targetWeight = 0; // Reference weight to balance against
        return findOptimalProduct(
          availableProducts,
          reservedProducts,
          targetWeight
        );
      } else {
        // Return first available product when not ordering by weight
        return availableProducts[0];
      }
    },

    hasOverlappingDates(product) {
      return hasOverlappingDates(product, this.cart, this.filterDates);
    },

    availableProductsCountPerType(type) {
      const products = this.getProductsPerType(type).filter(
        (product) => product.activated && product.available
      );
      return products.length;
    },

    getProductsPerType(type) {
      return this.products.filter((product) => product.type == type);
    },

    showAddToCartButton(type) {
      return (
        this.availableProductsCountPerType(type) &&
        !this.isProductInCart(this.getSelectedProductFromType(type)) &&
        !this.hasOverlappingDates(this.getSelectedProductFromType(type)) &&
        this.isWithinTimeConstraints(type)
      );
    },

    showRemoveFromCartButton(type) {
      const selectedProduct = this.getSelectedProductFromType(type);
      return (
        this.availableProductsCountPerType(type) &&
        this.isProductInCart(selectedProduct)
      );
    },

    shouldOrderByWeight(type) {
      return this.types.find((t) => t.label === type)?.orderByWeight;
    },

    isWithinTimeConstraints(type) {
      const typeConfig = this.types.find((t) => t.label === type);
      if (!typeConfig) return true;

      const timeDifference = calculateTimeDifferenceInMinutes(
        this.filterDates[0],
        this.filterDates[1]
      );

      return checkTimeConstraints(
        {
          minimumTime: typeConfig.minimumTime,
          maximumTime: typeConfig.maximumTime,
        },
        timeDifference
      );
    },

    getTimeConstraintMessage(type) {
      const typeConfig = this.types.find((t) => t.label === type);
      if (!typeConfig) return "";

      const timeDifference = calculateTimeDifferenceInMinutes(
        this.filterDates[0],
        this.filterDates[1]
      );

      const minTime = typeConfig.minimumTime;
      const maxTime = typeConfig.maximumTime;

      if (minTime && timeDifference < minTime) {
        return this.$t("product.timeConstraints.minDuration", {
          duration: formatDuration(minTime),
        });
      }
      if (maxTime && timeDifference > maxTime) {
        return this.$t("product.timeConstraints.maxDuration", {
          duration: formatDuration(maxTime),
        });
      }
      return "";
    },
  },
};
</script>

<style>
.price {
  text-align: right;
  font-weight: bold;
  font-size: 20px;
  background: #fff;
  padding: 5px 5px;
  border-radius: 100px;
  float: right;
  color: #303030 !important;
}

.product-container {
  box-shadow: 0 15px 30px 1px grey;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  margin: 0em auto;
}

@media only screen and (min-width: 901px) {
  .product-container {
    height: 230px;
    max-width: 90%;
  }

  .product-image {
    height: 100%;
    float: left;
    width: 30%;
  }

  .product-container img {
    height: 100%;
  }

  .product-container .product-details > p {
    text-align: left;
  }

  .product-container .product-details {
    padding: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .product-container {
    max-width: 95%;
    height: 300px;
    margin-top: 30px;
  }

  .product-image {
    height: 30%;
    float: top;
    width: 100%;
  }

  .product-container img {
    width: 100%;
  }

  .cart-button {
    float: bottom;
    margin-top: 10px;
  }

  .product-details {
    width: 95%;
    margin: auto;
  }

  .title-price h1 {
    float: left;
    padding-left: 5px;
  }

  .information {
    margin-top: 0;
    margin-left: 5px;
    text-align: left;
  }
}

.product-image {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.product-container .product-details h1 {
  font-size: 25px;
  color: #303030;
}

.product-details {
  overflow: hidden;
  height: 100%;
}

.product-container .product-details > p {
  font-size: 18px;
  color: #7d7d7d;
}

.cart-button {
  background-color: #119e8c;
  border-radius: 5px;
  padding: 2rem;
  display: flex;
  margin: auto;
}

.title-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.title-price h1 {
  font-size: 25px;
  color: #303030;
  margin: 0;
}

.information {
  white-space: nowrap;
  margin-bottom: 15px;
}

.button-text {
  margin-bottom: 0px !important;
}

.time-constraint-warning {
  color: #ff1744;
  font-size: 0.9em;
  margin: 5px 0;
  text-align: left;
  font-weight: bold;
}
</style>
