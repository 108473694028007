<template>
  <v-alert v-if="show" type="error" class="ma-0" prominent text>
    <div class="text-subtitle-1 mb-2">
      {{ $t("product.overtime.message") }}
    </div>
    <div class="text-body-2">
      {{ $t("product.overtime.action_required") }}
    </div>
  </v-alert>
</template>

<script>
export default {
  name: "RegularizeOvertimeAlert",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
