export const hasOverlappingDates = (product, cart, filterDates) => {
  const sameProductsInCart = cart.filter((p) => p.product_id == product.id);
  if (!sameProductsInCart.length) return false;

  const [start, end] = filterDates;
  return sameProductsInCart.some((sameProductInCart) => {
    const [pStart, pEnd] = [
      new Date(sameProductInCart.start_date * 1000),
      new Date(sameProductInCart.end_date * 1000),
    ];

    return (
      (pStart >= start && pStart <= end) ||
      (pEnd >= start && pEnd <= end) ||
      (pStart <= start && pEnd >= end)
    );
  });
};
