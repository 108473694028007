<template>
  <div>
    <v-dialog v-model="show" max-width="500px">
      <v-card>
        <v-card-title>{{ $t("product.extendBooking.title") }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>{{ $t("product.extendBooking.message") }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <time-filter
                  v-model="selectedDates"
                  :label="$t('product.extendBooking.newEndDate')"
                  :mode="timeFilterMode"
                  :enforcedMinDate="
                    booking ? new Date(booking.endDate) : undefined
                  "
                  :lockStartDate="true"
                  :selectedProductTypes="
                    booking ? [booking.product.type] : undefined
                  "
                  :area="booking ? booking.product.area : undefined"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div class="text-subtitle-1">
                  {{ $t("product.extendBooking.currentDuration") }}:
                  <span class="font-weight-bold">{{
                    formattedCurrentDuration
                  }}</span>
                </div>
                <div class="text-subtitle-1">
                  {{ $t("product.extendBooking.newDuration") }}:
                  <span class="font-weight-bold">{{
                    formattedNewDuration
                  }}</span>
                </div>
                <div class="text-subtitle-1">
                  {{ $t("product.extendBooking.additionalCost") }}:
                  <span class="font-weight-bold"
                    >{{ formattedAdditionalCost }} €</span
                  >
                </div>
                <div class="text-subtitle-1">
                  {{ $t("product.extendBooking.totalCost") }}:
                  <span class="font-weight-bold"
                    >{{ formattedTotalCost }} €</span
                  >
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="close">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            @click="confirmExtend"
            :disabled="!canExtend"
          >
            {{ $t("product.extendBooking.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="6000">
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script>
import TimeFilter from "./time-filter.vue";
import { getReservationPrice } from "../utils/getReservationPrice";

export default {
  name: "ExtendBookingDialog",
  components: {
    TimeFilter,
  },
  props: {
    value: Boolean,
    booking: Object,
  },
  data() {
    return {
      loading: false,
      selectedDates: [],
      snackbar: {
        show: false,
        text: "",
        color: "success",
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    timeFilterMode() {
      return process.env.VUE_APP_TIME_MODE ?? "hours";
    },
    formattedCurrentDuration() {
      if (!this.booking) return "";
      const startDate = new Date(this.booking.startDate);
      const endDate = new Date(this.booking.endDate);
      const diffMs = endDate - startDate;
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}m`;
    },
    formattedNewDuration() {
      if (!this.booking || !this.selectedDates[1]) return "";
      const startDate = new Date(this.booking.startDate);
      const newEndDate = new Date(this.selectedDates[1]);
      const diffMs = newEndDate - startDate;
      const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      return `${diffHours}h ${diffMinutes.toString().padStart(2, "0")}m`;
    },
    formattedAdditionalCost() {
      if (!this.booking || !this.selectedDates[1]) return "0.00";
      const oldEndDate = new Date(this.booking.endDate);
      const newEndDate = new Date(this.selectedDates[1]);
      const diffMs = newEndDate - oldEndDate;
      const diffHours = Math.ceil(diffMs / (1000 * 60 * 60));

      return getReservationPrice(
        diffHours,
        this.booking.product.type.price
      ).toFixed(2);
    },
    formattedTotalCost() {
      if (!this.booking) return "0.00";
      const currentCost = this.booking.price / 100;
      const additionalCost = parseFloat(this.formattedAdditionalCost);
      return (currentCost + additionalCost).toFixed(2);
    },
    canExtend() {
      if (!this.booking) return false;
      return (
        this.selectedDates[1] &&
        new Date(this.selectedDates[1]) > new Date(this.booking.endDate)
      );
    },
  },
  methods: {
    async confirmExtend() {
      this.loading = true;

      try {
        const origin = location.origin;
        const successUrl = `${origin}/booking?result=success&type=extend&orderId=${this.booking.orderId}`;
        const cancelUrl = `${origin}/orders`;

        const response = await this.$api.user.booking.extend(this.booking.id, {
          newEndDate: new Date(this.selectedDates[1]).getTime() / 1000,
          successUrl,
          cancelUrl,
        });

        const { paymentUrl, status } = response;

        if (!paymentUrl) {
          if (status === "OK") {
            this.$router.push(
              `/booking?result=success&type=extend&orderId=${this.booking.orderId}`
            );
          } else {
            this.$set(this, "snackbar", {
              show: true,
              text: this.$t("product.extendBooking.error"),
              color: "error",
            });
          }
        } else {
          // Redirect to Stripe payment
          location = paymentUrl;
        }
      } catch (error) {
        console.error(error);
        this.$set(this, "snackbar", {
          show: true,
          text: this.$t("product.extendBooking.error"),
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.selectedDates = [];
      this.show = false;
    },
  },
  watch: {
    show(val) {
      if (val && this.booking) {
        this.selectedDates = [
          new Date(this.booking.startDate),
          new Date(this.booking.endDate),
        ];
      }
    },
  },
};
</script>
